<template>
  <div class="portal-head">
    <div class="head-top">
      <!-- <el-tooltip effect="dark" popper-class="zewm" placement="bottom" :visible-arrow="false" content="">
        <img slot="content" src="@/assets/images/ewm.jpg" width="100" />
        <span>协会公众号</span>
      </el-tooltip> -->

      <!-- 未登录 -->
      <span v-if="!ifLogin" style="cursor: pointer;" @click="toLogin">登录</span>
      <span style="width: 1px;height: 16px;background: #caced7;margin: 0 12px;"></span>
      <span v-if="!ifLogin" style="cursor: pointer;" @click="toRegister">注册</span>

      <!-- 已登录 -->
      <span v-if="ifLogin" style="cursor: default;">{{ userName }}</span>
      <span v-if="ifLogin" @click="toLogout" style="display:flex;align-items:center">
        退出
        <div class="img-css quit-img"></div>
      </span>
    </div>
    <div class="head-main flex-row-between">
      <div>
        <div class="system-title">职业健康助企为民服务平台</div>
        <div class="title-tips">实现职业健康管理与服务业务“一网通办”</div>
      </div>
      <div class="input-box flex-row">
        <el-form class="zwx-form edit-form" style="width:308px" key="mainForm" ref="mainForm" :model="mainForm" @submit.native.prevent>
          <el-form-item label-width="" label="">
            <el-input class="zwx-input" style="width:308px !important" v-model="mainForm.compNo" placeholder="请输入关键字进行搜索" maxlength="50" @input="compNoInput" />
          </el-form-item>
        </el-form>
        <div class="search-box" @click="search">查询</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PortalHead',
  data() {
    return {
      api: this.$store.state.api,
      file: this.$store.state.file,
      ifLogin: false,
      userName: '',
      loading: null,
      mainForm: {
        compNo: '',//GDTS20240500001
      },
    }
  },
  created() { },
  mounted() {
    this.ifLogin = this.$zwxCookie.getCookie('msTokenId')
    this.userName = this.$zwxCookie.getCookie('userName')
  },
  methods: {
    //点击登录
    toLogin() {
      this.$emit('toLogin')
    },
    //点击注册
    toRegister() {
      this.$emit('toRegister')
    },
    //点击退出
    toLogout() {
      this.$system.post(this.api + '/hka/userLogout-1', {}, true, true, this.logoutSuccess, this.logoutSuccess)
      this.$emit('toLogout')
    },
    logoutSuccess() {
      // this.$system.notify('成功', '退出成功', 'success')
      this.$message({
        message: '退出成功',
        type: 'success',
      })
      // this.$zwxCookie.delCookie('msTokenId')
      this.$zwxCookie.delCookie('userRole')
      setTimeout(() => {
        this.$router.go(0)
      }, 300)
    },
    search() {
      if (!this.mainForm.compNo) return
      this.loading = this.$loading({
        lock: true,
        text: '',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.3)',
      })
      let data = {
        compNo: this.mainForm.compNo
      }
      this.$system.post(
        this.api + '',
        data,
        false,
        true,
        data => {
          if (data.type === '00') {

            // if (this.moduleWay == 1) {
            //   this.$message({ message: '未查询到相关信息', type: 'warning' })
            // }
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
          this.loading && this.loading.close()
          this.loading = null
        },
        data => { }
      )
    },
    compNoInput(val) {
      // !val && (this.resultInfo = {}, this.moduleWay = 1)
    },
    storageHandler() {
      let token = false
      if (!this.$zwxCookie.getCookie('msTokenId')) {
        token = false
      } else {
        token = true
      }
      if (this.ifLogin !== token) {
        this.$emit('logChange', token)
      }
      this.ifLogin = token
    },
  },
  destroyed() {
    window.removeEventListener('storage', this.storageHandler, false)
  },
}
</script>

<style lang="less" scoped>
.portal-head {
  height: 308px;
  width: 100%;
  background: linear-gradient(0deg, rgba(32, 135, 239, 0.22) 0%, rgba(31, 135, 239, 0.02) 100%);
  .head-top {
    padding: 16px 24px 0 0;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    font-size: 16px;
    color: #3b3b3b;
    line-height: 20px;

    .quit-img {
      width: 20px;
      height: 20px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url('../../assets/images/portal/ic_header_quit.png');
      margin-left: 5px;
      cursor: pointer;
    }
  }
  .head-main {
    width: 1200px;
    margin: 0 auto;
    .system-title {
      width: 672px;
      height: 56px;
      font-size: 56px;
      color: #0f56b6;
      line-height: 56px;
      // text-shadow: -4px -4px 0px 0px #ffffff;
      text-shadow: -5px -2px 0px #ffffff;
    }
    .title-tips {
      width: 395px;
      height: 22px;
      font-size: 22px;
      color: #3b3b3b;
      line-height: 22px;
      margin-top: 24px;
    }
    .input-box {
      height: 260px;
      .search-box {
        width: 87px;
        height: 58px;
        background: linear-gradient(270deg, #256fe4, #1f87ef 50%, #3c98f7);
        border-radius: 2px;
        box-shadow: 2px 2px 4px 0px rgba(162, 179, 200, 0.68);
        font-size: 22px;
        color: #ffffff;
        line-height: 58px;
        text-align: center;
        margin-left: 16px;
        cursor: pointer;
        &:hover {
          background: #256fe4;
        }
      }
      /deep/.zwx-form .el-form-item {
        height: auto !important;
        line-height: 58px !important;
        margin: 0 !important;
      }
      /deep/.zwx-form .el-form-item__content {
        height: auto !important;
        line-height: 58px !important;
      }
      /deep/.zwx-input {
        height: auto !important;
        line-height: 58px !important;
        border: 1px solid #2570e5;
        border-radius: 2px;
        box-shadow: 2px 2px 4px 0px rgba(162, 179, 200, 0.68);
      }
      /deep/.el-input__inner {
        height: 58px !important;
        border: none !important;
      }
      /deep/input::-webkit-input-placeholder {
        font-size: 18px;
        line-height: 20px;
        -webkit-text-fill-color: #3d3d3d;
      }
    }
  }
}
/deep/ .el-tooltip__popper.is-dark {
  background-color: #fff !important;
}
/deep/ .el-tooltip__popper {
  background-color: white !important;
}
</style>

<style lang="less">
.zewm {
  background-color: #fff !important;
  padding: 0px !important;
}
.zewm::after {
  border-top-color: white !important;
}
</style>
