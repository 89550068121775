var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "footer flex-column-center" }, [
    _c("div", { staticClass: "footer-body flex-column-center" }, [
      _c("div", { staticStyle: { "margin-bottom": "33px" } }, [
        _vm._v(" ©2021国家卫生健康委职业安全卫生研究中心all rights reserved ")
      ]),
      _c("div", { staticClass: "flex-row-center" }, [
        _c("div", [
          _vm._v("技术支持：" + _vm._s(_vm.unitName) + " "),
          _c(
            "span",
            {
              staticStyle: { cursor: "pointer", "margin-left": "5px" },
              on: { click: _vm.domainNumberClick }
            },
            [_vm._v(_vm._s(_vm.domainNumber))]
          )
        ]),
        _c("div", { staticClass: "line" }),
        _c("img", {
          staticStyle: {
            width: "14px",
            height: "14px",
            "margin-right": "12px"
          },
          attrs: {
            src: require("../../assets/images/portal/img-national-emblem.png"),
            alt: ""
          }
        }),
        _c(
          "div",
          { staticStyle: { cursor: "pointer" }, on: { click: _vm.psnClick } },
          [_vm._v(_vm._s(_vm.psn))]
        ),
        _c("div", { staticClass: "line" }),
        _c("div", [_vm._v("用户访问：" + _vm._s(_vm.userVisits))])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }