/**
 * employer模块
 */
import { PageLayout } from 'vue-el-web'
// 一个菜单对应一个父级路由
// 一个父级路由下面不允许存在多级children
// noRequireAuth：表示不需要进行登录验证，也不需要进行菜单权限验证
// menuRoute：表示需要进行登录验证，也需要进行菜单权限验证，但是路由切换不会再次校验登录信息
// noMenuRoute：表示需要进行登录验证，但不需要进行菜单权限验证，路由切换会再次校验登录信息
// 无：表示不需要进行登录验证，也不需要进行菜单权限验证，也表示该页面不能直接打开，需要通过路由跳转
const viewsTest = [
  /**
   * 企业基本档案
   */
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/hostingService/structure/employerBasic',
        name: 'EmployerBasic',
        component: () => import('@/views/employer/basic_management/EmployerBasic.vue'),
        meta: { menuRoute: true, title: '企业信息管理' },
      },
    ],
  },
  /**
   *  岗位工种管理
   */
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/hostingService/structure/jobInfo',
        name: 'JobInfo',
        component: () => import('@/views/employer/basic_management/job_info/JobInfo.vue'),
        meta: { noRequireAuth: true, menuRoute: true, title: '岗位工种管理' },
      },
      {
        path: '/hostingService/structure/jobInfoDetail',
        name: 'JobInfoDetail',
        component: () => import('@/views/employer/basic_management/job_info/JobInfoDetail.vue'),
        meta: { noRequireAuth: true, menuRoute: true, title: '详情' },
      },
    ],
  },
  /**
   * 部门岗位管理
   */
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/hostingService/structure/departmentStation',
        name: 'DepartmentStation',
        component: () => import('@/views/employer/basic_management/DepartmentStation.vue'),
        meta: { menuRoute: true, title: '部门岗位管理' },
      },
    ],
  },
  /**
   * 员工档案管理
   */ {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/hostingService/structure/staffInductionManagement',
        name: 'StaffInductionMainPage',
        component: () => import('@/views/employer/staff_management/staff_induction/MainPage.vue'),
        meta: { menuRoute: true, title: '员工档案管理' },
      },
      {
        path: '/hostingService/structure/staffInductionEditPage',
        name: 'StaffInductionEditPage',
        component: () => import('@/views/employer/staff_management/staff_induction/EditPage.vue'),
        meta: { menuRoute: true, title: '编辑' },
      },

      {
        path: '/hostingService/structure/staffInductionDetailPage',
        name: 'StaffInductionDetailPage',
        component: () => import('@/views/employer/staff_management/staff_induction/DetailPage.vue'),
        meta: { menuRoute: true, title: '详情' },
      },
    ],
  },
  /**
   * 危害申报管理（完整申报）
   */
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/hostingService/occupational/hazardDeclaration',
        name: 'HazardDeclaration',
        component: () => import('@/views/employer/occupational_health_management/hazard_declaration/ListPage.vue'),
        meta: { menuRoute: true, title: '危害申报管理' },
      },
      {
        path: '/hostingService/occupational/hazardDeclaration/edit',
        name: 'HazardDeclarationEdit',
        component: () => import('@/views/employer/occupational_health_management/hazard_declaration/EditPage.vue'),
        meta: { title: '编辑' },
      },
      {
        path: '/hostingService/occupational/hazardDeclaration/view',
        name: 'HazardDeclarationView',
        component: () => import('@/views/employer/occupational_health_management/hazard_declaration/ViewPage.vue'),
        meta: { title: '查看' },
      },
    ],
  },
  /**
   * 三同时项目管理
   */
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/hostingService/structure/threeSimultaneousManagement',
        name: 'ThreeSimultaneousMainPage',
        component: () => import('@/views/employer/occupational_health_management/three_simultaneous/MainPage.vue'),
        meta: { menuRoute: true, title: '三同时项目管理' },
      },
      {
        path: '/hostingService/structure/threeSimultaneousEditPage',
        name: 'ThreeSimultaneousEditPage',
        component: () => import('@/views/employer/occupational_health_management/three_simultaneous/EditPage.vue'),
        meta: { menuRoute: true, title: '三同时项目编辑' },
      },
    ],
  },
  /**
   * 企业基本信息
   */
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/employer/structure/employerBasic',
        name: 'EmployerBasic',
        component: () => import('../views/employer/basic_management/EmployerBasic.vue'),
        meta: { menuRoute: true, title: '企业信息管理' },
      },
    ],
  },
  /**
   * 体检记录
   */
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/hostingService/healthSupervision/peRecordManagement',
        name: 'PERecordManagement',
        component: () => import('../views/employer/health_supervision/pe_record_management/ListPage.vue'),
        meta: { menuRoute: true, title: '体检记录管理' },
      },
      // {
      //   path: '/hostingService/healthSupervision/peRecordDetail',
      //   name: 'PeRecordDetail',
      //   component: () => import('../views/employer/health_supervision/pe_record_management/DetailPage.vue'),
      //   meta: { menuRoute: true, title: '详情' },
      // },
    ],
  },
]

export default viewsTest
