var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          key: "msForm",
          ref: "msForm",
          staticClass: "zwx-form edit-form",
          attrs: {
            model: _vm.msForm,
            rules: _vm.rules,
            "label-position": "right"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c("base-headline", {
            staticClass: "no-top",
            attrs: { title: "基本信息" }
          }),
          _c("div", { staticStyle: { "margin-top": "10px" } }, [
            _c("div", { staticClass: "condition-row" }, [
              _c(
                "div",
                { staticClass: "mstype" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "110px",
                        label: "类别：",
                        prop: "pointType"
                      }
                    },
                    [
                      _c("cascader-options-multi", {
                        ref: "mscCascader",
                        attrs: {
                          options: _vm.simpleCodeList,
                          dataNameFiled: "codeName",
                          dataCodeFiled: "codeNo",
                          dataUpCodeFiled: "children",
                          codeLevelNo: "codeLevelNo",
                          width: "260px",
                          showFullTag: false,
                          collapsetags: true
                        },
                        on: { propEvent: _vm.propEvent },
                        model: {
                          value: _vm.msForm.pointType,
                          callback: function($$v) {
                            _vm.$set(_vm.msForm, "pointType", $$v)
                          },
                          expression: "msForm.pointType"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "condition-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "题型：",
                      "label-width": "110px",
                      prop: "topicType"
                    }
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "zwx-select topic-select",
                        attrs: {
                          "popper-class": "zwx-select-popper",
                          placeholder: "请选择"
                        },
                        model: {
                          value: _vm.msForm.topicType,
                          callback: function($$v) {
                            _vm.$set(_vm.msForm, "topicType", $$v)
                          },
                          expression: "msForm.topicType"
                        }
                      },
                      _vm._l(_vm.options, function(item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "130px",
                      label: "选项排列方式：",
                      prop: "optionLayout"
                    }
                  },
                  [
                    _c(
                      "el-popover",
                      { attrs: { placement: "top", trigger: "hover" } },
                      [
                        _c("div", [
                          _vm._v("1、若选项都为图片，则默认纵向排列"),
                          _c("br"),
                          _vm._v("2、若选项文字过多，建议纵向排列")
                        ]),
                        _c("i", {
                          staticClass: "el-icon-info",
                          staticStyle: {
                            color: "#606266",
                            "margin-right": "10px"
                          },
                          attrs: { slot: "reference" },
                          slot: "reference"
                        })
                      ]
                    ),
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "zwx-radio-group",
                        staticStyle: { width: "200px !important" },
                        attrs: { disabled: _vm.optionDisable },
                        model: {
                          value: _vm.msForm.optionLayout,
                          callback: function($$v) {
                            _vm.$set(_vm.msForm, "optionLayout", $$v)
                          },
                          expression: "msForm.optionLayout"
                        }
                      },
                      [
                        _c(
                          "el-radio",
                          { staticClass: "zwx-radio", attrs: { label: 1 } },
                          [_vm._v("纵向")]
                        ),
                        _c(
                          "el-radio",
                          { staticClass: "zwx-radio", attrs: { label: 2 } },
                          [_vm._v("横向")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "condition-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "110px",
                      label: "题目：",
                      prop: "topicContent"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      staticStyle: { width: "640px !important" },
                      attrs: {
                        placeholder: "请输入",
                        clearable: "",
                        maxlength: "500"
                      },
                      model: {
                        value: _vm.msForm.topicContent,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.msForm,
                            "topicContent",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "msForm.topicContent"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "condition-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "110px",
                      label: "题目解析说明：",
                      prop: "topicAnalysis"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-textarea",
                      staticStyle: { width: "640px !important" },
                      attrs: {
                        type: "textarea",
                        autosize: { minRows: 5 },
                        maxlength: "2000",
                        resize: "none",
                        placeholder: "请输入",
                        clearable: ""
                      },
                      model: {
                        value: _vm.msForm.topicAnalysis,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.msForm,
                            "topicAnalysis",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "msForm.topicAnalysis"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "condition-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "110px",
                      label: "图片上传：",
                      prop: "annexList"
                    }
                  },
                  [
                    _c(
                      "file-upload",
                      {
                        ref: "picFileRef",
                        attrs: {
                          accept: ".png,.jpeg,.jpg,.JPG,.JPEG,.PNG,",
                          size: 50 * 1024 * 1024,
                          sizeInfo: "50M"
                        },
                        on: {
                          showFileList: _vm.picFileShowFileList,
                          success: _vm.picFileUploadSuccess
                        }
                      },
                      [
                        _vm.$zwxBase.verifyIsBlank(_vm.msForm.annexList)
                          ? _c(
                              "el-button",
                              {
                                staticClass: "zwx-button zwx-button-text-26",
                                attrs: { icon: "el-icon-upload2" }
                              },
                              [_vm._v("上传")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm.$zwxBase.verifyIsNotBlank(_vm.msForm.annexList)
                      ? _c(
                          "div",
                          { staticClass: "show-file" },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "zwx-button zwx-button-text-26",
                                on: {
                                  click: function($event) {
                                    return _vm.openFilePreview1(
                                      _vm.msForm.annexList,
                                      0
                                    )
                                  }
                                }
                              },
                              [_vm._v("查看")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "zwx-button zwx-button-text-26",
                                attrs: { type: "danger" },
                                on: {
                                  click: function($event) {
                                    return _vm.deletionPicFile(0)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "base-headline",
            { staticClass: "no-bottom", attrs: { title: "题目选项" } },
            [
              _c(
                "el-button",
                {
                  staticClass: "zwx-button zwx-button-icontext-28",
                  attrs: { icon: "el-icon-plus" },
                  on: { click: _vm.addOption }
                },
                [_vm._v("添加")]
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              key: "msForm.trainingOptionList",
              staticClass: "zwx-table",
              attrs: {
                data: _vm.msForm.trainingOptionList,
                border: "",
                stripe: "",
                "tooltip-effect": "light"
              }
            },
            [
              _c(
                "el-table-column",
                {
                  attrs: {
                    prop: "optionCode",
                    label: "选项",
                    width: "200",
                    "header-align": "center",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                id: "optionCode" + scope.$index,
                                prop:
                                  "trainingOptionList." +
                                  scope.$index +
                                  ".optionCode",
                                rules: _vm.rules.optionCode
                              }
                            },
                            [
                              _c("el-input", {
                                staticClass: "zwx-input",
                                attrs: {
                                  placeholder: "",
                                  clearable: "",
                                  maxlength: "20"
                                },
                                model: {
                                  value: scope.row.optionCode,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "optionCode", $$v)
                                  },
                                  expression: "scope.row.optionCode"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c("span", [_vm._v("选项")]),
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "right",
                            title: "",
                            width: "200",
                            trigger: "hover"
                          }
                        },
                        [
                          _c("div", [
                            _c("div", [_c("span", [_vm._v("A.****")])]),
                            _c("div", [_c("span", [_vm._v("B.****")])]),
                            _c("div", [_c("span", [_vm._v("C.****")])]),
                            _c("div", [_c("span", [_vm._v("D.****")])])
                          ]),
                          _c("i", {
                            staticClass:
                              "outline chis-icon-outline-information1",
                            staticStyle: {
                              color: "#FF8327",
                              "margin-left": "5px",
                              cursor: "pointer"
                            },
                            attrs: { slot: "reference" },
                            slot: "reference"
                          })
                        ]
                      )
                    ],
                    1
                  )
                ],
                2
              ),
              _c("el-table-column", {
                attrs: {
                  prop: "optionContent",
                  label: "选项内容",
                  width: "600",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              id: "optionContent" + scope.$index,
                              prop:
                                "trainingOptionList." +
                                scope.$index +
                                ".optionContent",
                              rules: _vm.rules.optionContent
                            }
                          },
                          [
                            _c("el-input", {
                              staticClass: "zwx-input",
                              attrs: {
                                placeholder: "",
                                clearable: "",
                                maxlength: "200"
                              },
                              model: {
                                value: scope.row.optionContent,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "optionContent", $$v)
                                },
                                expression: "scope.row.optionContent"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "ifAnswer",
                  label: "是否答案",
                  width: "150",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              id: "ifAnswer" + scope.$index,
                              prop:
                                "trainingOptionList." +
                                scope.$index +
                                ".ifAnswer"
                            }
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "zwx-checkbox if-answer",
                              model: {
                                value: scope.row.ifAnswer,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "ifAnswer", $$v)
                                },
                                expression: "scope.row.ifAnswer"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  width: "160",
                  prop: "picPath",
                  label: "图片",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop:
                                "trainingOptionList." +
                                scope.$index +
                                ".picPath"
                            }
                          },
                          [
                            _c(
                              "file-upload",
                              {
                                ref: "msPositive" + scope.row.uuid,
                                attrs: {
                                  accept: ".png,.jpeg,.jpg,.JPG,.JPEG,.PNG",
                                  size: 50 * 1024 * 1024,
                                  sizeInfo: "50M"
                                },
                                on: {
                                  showFileList: function($event) {
                                    return _vm.clickPositive(
                                      $event,
                                      scope.$index,
                                      scope.row.uuid
                                    )
                                  },
                                  success: function($event) {
                                    return _vm.clickPositiveSuccess(
                                      $event,
                                      scope.$index
                                    )
                                  },
                                  error: function($event) {
                                    return _vm.clickError()
                                  }
                                }
                              },
                              [
                                _vm.$zwxBase.verifyIsBlank(
                                  _vm.msForm.trainingOptionList[scope.$index]
                                    .optionPicList
                                ) ||
                                _vm.msForm.trainingOptionList[scope.$index]
                                  .optionPicList.length < 1
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass:
                                          "zwx-button zwx-button-text-26",
                                        attrs: { type: "text" }
                                      },
                                      [_vm._v("上传")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm.msForm.trainingOptionList[scope.$index]
                              .optionPicList.length > 0
                              ? _c(
                                  "span",
                                  { staticClass: "show-file" },
                                  _vm._l(
                                    _vm.msForm.trainingOptionList[scope.$index]
                                      .optionPicList,
                                    function(item, index) {
                                      return _c(
                                        "span",
                                        {
                                          key: item.fileName,
                                          on: {
                                            mouseenter: function($event) {
                                              return _vm.mouseenter(
                                                scope.$index,
                                                index
                                              )
                                            },
                                            mouseleave: function($event) {
                                              return _vm.mouseleave(
                                                scope.$index,
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass:
                                                "zwx-button zwx-button-text-26",
                                              on: {
                                                click: function($event) {
                                                  return _vm.openFilePreview1(
                                                    _vm.msForm
                                                      .trainingOptionList[
                                                      scope.$index
                                                    ].optionPicList,
                                                    0
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("查看")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              staticClass:
                                                "zwx-button zwx-button-text-26",
                                              attrs: { type: "danger" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deletionPositive(
                                                    scope.row.uuid,
                                                    scope.$index,
                                                    index
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("删除")]
                                          )
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "160",
                  "header-align": "left",
                  align: "left"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "zwx-button zwx-button-text-26",
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.deleteItem(scope.$index)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "fixed-footer",
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-32",
              attrs: { type: "primary", disabled: _vm.saveButtonDisabled },
              on: { click: _vm.addOrModifyTbEmployerInvestigation }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }