var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "portal-head" }, [
    _c("div", { staticClass: "head-top" }, [
      !_vm.ifLogin
        ? _c(
            "span",
            { staticStyle: { cursor: "pointer" }, on: { click: _vm.toLogin } },
            [_vm._v("登录")]
          )
        : _vm._e(),
      _c("span", {
        staticStyle: {
          width: "1px",
          height: "16px",
          background: "#caced7",
          margin: "0 12px"
        }
      }),
      !_vm.ifLogin
        ? _c(
            "span",
            {
              staticStyle: { cursor: "pointer" },
              on: { click: _vm.toRegister }
            },
            [_vm._v("注册")]
          )
        : _vm._e(),
      _vm.ifLogin
        ? _c("span", { staticStyle: { cursor: "default" } }, [
            _vm._v(_vm._s(_vm.userName))
          ])
        : _vm._e(),
      _vm.ifLogin
        ? _c(
            "span",
            {
              staticStyle: { display: "flex", "align-items": "center" },
              on: { click: _vm.toLogout }
            },
            [_vm._v(" 退出 "), _c("div", { staticClass: "img-css quit-img" })]
          )
        : _vm._e()
    ]),
    _c("div", { staticClass: "head-main flex-row-between" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "input-box flex-row" },
        [
          _c(
            "el-form",
            {
              key: "mainForm",
              ref: "mainForm",
              staticClass: "zwx-form edit-form",
              staticStyle: { width: "308px" },
              attrs: { model: _vm.mainForm },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { "label-width": "", label: "" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "308px !important" },
                    attrs: {
                      placeholder: "请输入关键字进行搜索",
                      maxlength: "50"
                    },
                    on: { input: _vm.compNoInput },
                    model: {
                      value: _vm.mainForm.compNo,
                      callback: function($$v) {
                        _vm.$set(_vm.mainForm, "compNo", $$v)
                      },
                      expression: "mainForm.compNo"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "search-box", on: { click: _vm.search } }, [
            _vm._v("查询")
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "system-title" }, [
        _vm._v("职业健康助企为民服务平台")
      ]),
      _c("div", { staticClass: "title-tips" }, [
        _vm._v("实现职业健康管理与服务业务“一网通办”")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }