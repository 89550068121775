<template>
  <div class="drag" ref="dragDiv">
    <div class="drag_bg"></div>
    <div class="drag_text">{{ confirmWords }}</div>
    <div ref="moveDiv" v-if="isShow" @mousedown="mouseDownFn($event)" :class="{ handler_ok_bg: confirmSuccess }" class="handler handler_bg" style="position: absolute;top: 0;left: 0;" :style="{left:confirmSuccess?'maxWidth':'0'}"></div>
    <div ref="moveDiv" v-else @touchstart="touchstart($event)" :class="{ handler_ok_bg: confirmSuccess }" class="handler handler_bg" style="position: absolute;top: 0;left: 0;" :style="{left:confirmSuccess?'maxWidth':'0'}"></div>
  </div>
</template>

<script>
export default {
  name: 'SliderCheck',
  data() {
    return {
      beginClientX: 0 /*距离屏幕左端距离*/,
      mouseMoveState: false /*触发拖动状态  判断*/,
      maxWidth: '' /*拖动最大宽度，依据滑块宽度算出来的*/,
      confirmWords: '请按住滑块，拖动到最右端' /*滑块文字*/,
      confirmSuccess: false /*验证成功判断*/,
      isShow: window.screen.width < 1000 ? false : true,
      isDrag: false,
      startX: 0, // 点击开始位置
      moveX: 0, // 移动距离
      currentX: 0, // 当前位置
      startTime: 0,
      endTime: 0,
      pointList: [],
    }
  },
  // watch: {
  //   confirmSuccess(newValue) {
  //     this.$emit('change', newValue)
  //   }
  // },
  created() { },
  mounted() {
    this.$nextTick(() => {
      this.maxWidth = this.$refs.dragDiv.clientWidth - this.$refs.moveDiv.clientWidth
    })
    window.addEventListener('resize', this.resizeWidth)
  },
  methods: {
    touchstart(e) {
      if (!this.confirmSuccess) {
        this.mouseMoveState = true
        this.beginClientX = e.touches[0].clientX
      }
      if (this.confirmSuccess) return
      this.startTime = Date.now()
      document.addEventListener('touchmove', this.touchmoveEvent)
      document.addEventListener('touchend', this.touchendEvent)
      e.stopPropagation()
    },

    touchmoveEvent(e) {
      if (this.mouseMoveState) {
        const width = e.touches[0].clientX - this.beginClientX
        if (width > 0 && width <= this.maxWidth) {
          document.getElementsByClassName('drag_text')[0].style.color = '#000'
          document.getElementsByClassName('drag_bg')[0].style.width = width + 'px'
          document.getElementsByClassName('handler')[0].style.left = width + 'px'
          this.pointList.push({ x: e.clientX, y: e.clientY })
        } else if (width > this.maxWidth) {
          this.successFunction()
        }
      }

      e.stopPropagation()
    },

    touchendEvent(e) {
      if (!this.confirmSuccess) {
        this.startTime = 0
        this.pointList.splice(0, this.pointList.length)
      }
      this.mouseMoveState = false
      const width = e.changedTouches[0].clientX - this.beginClientX
      if (width < this.maxWidth) {
        document.getElementsByClassName('drag_text')[0].style.color = '#b5bac2'
        document.getElementsByClassName('drag_bg')[0].style.width = 0 + 'px'
        document.getElementsByClassName('handler')[0].style.left = 0 + 'px'
      }
      document.removeEventListener('touchmove', this.touchmoveEvent)
      document.removeEventListener('touchend', this.touchendEvent)
      e.stopPropagation()
    },

    resizeWidth() {
      this.$nextTick(() => {
        this.maxWidth = this.$refs.dragDiv.clientWidth - this.$refs.moveDiv.clientWidth
        if (this.confirmSuccess) {
          document.getElementsByClassName('drag_bg')[0].style.width = this.maxWidth + 'px'
          document.getElementsByClassName('handler')[0].style.left = this.maxWidth + 'px'
        }
      })
    },
    //mousedown 事件
    mouseDownFn(e) {
      if (!this.confirmSuccess) {
        e.preventDefault && e.preventDefault() //阻止文字选中等 浏览器默认事件
        this.mouseMoveState = true
        this.beginClientX = e.clientX
      }
      if (this.confirmSuccess) return
      this.startTime = Date.now()
      document.onmousemove = this.mouseMoveFn
      document.onmouseup = this.moseUpFn
    },
    //验证成功函数
    successFunction() {
      this.mouseMoveState = false
      this.confirmSuccess = true
      this.confirmWords = '验证通过'
      this.endTime = Date.now()
      document.getElementsByClassName('drag_text')[0].style.color = '#fff'
      document.getElementsByClassName('drag_bg')[0].style.width = this.maxWidth + 'px'
      document.getElementsByClassName('handler')[0].style.left = this.maxWidth + 'px'
      const data = {
        startTime: this.startTime,
        endTime: this.endTime,
        pointList: this.pointList,
      }
      this.$emit('change', data)
    },
    //mousemove事件
    mouseMoveFn(e) {
      if (this.mouseMoveState) {
        const width = e.clientX - this.beginClientX
        if (width > 0 && width <= this.maxWidth) {
          document.getElementsByClassName('drag_text')[0].style.color = '#000'
          document.getElementsByClassName('drag_bg')[0].style.width = width + 'px'
          document.getElementsByClassName('handler')[0].style.left = width + 'px'
          this.pointList.push({ x: e.clientX, y: e.clientY })
        } else if (width <= 0) {
          document.getElementsByClassName('drag_text')[0].style.color = '#b5bac2'
          document.getElementsByClassName('drag_bg')[0].style.width = 0 + 'px'
          document.getElementsByClassName('handler')[0].style.left = 0 + 'px'
        } else if (width > this.maxWidth) {
          this.successFunction()
        }
      }
    },
    //mouseup事件
    moseUpFn(e) {
      if (!this.confirmSuccess) {
        this.startTime = 0
        this.pointList.splice(0, this.pointList.length)
      }
      this.mouseMoveState = false
      const width = e.clientX - this.beginClientX
      if (width < this.maxWidth) {
        document.getElementsByClassName('drag_text')[0].style.color = '#b5bac2'
        document.getElementsByClassName('drag_bg')[0].style.width = 0 + 'px'
        document.getElementsByClassName('handler')[0].style.left = 0 + 'px'
      }
      document.onmousemove = null
      document.onmouseup = null
    },
    // 初始化
    initSliderCheck() {
      this.confirmWords = '请按住滑块，拖动到最右端'
      this.confirmSuccess = false
      document.getElementsByClassName('drag_text')[0].style.color = '#b5bac2'
      document.getElementsByClassName('drag_bg')[0].style.width = 0 + 'px'
      document.getElementsByClassName('handler')[0].style.left = 0 + 'px'
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeWidth)
  },
}
</script>

<style lang="less" scoped>
.drag {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: #f2f4f9;
  border-radius: 4px;
}
.handler {
  width: 50px;
  height: 48px;
  cursor: move;
  background: #fff;
  border: 1px solid #e9ecf2;
  border-radius: 2px;
}
.handler_bg {
  background: #fff url('../../../assets/images/login/login_ic_right.svg') no-repeat center;
}
.handler_ok_bg {
  background: #fff url('../../../assets/images/login/img_success.svg') no-repeat center;
  border-radius: 50% !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  scale: 0.5;
}
.drag_bg {
  width: 0;
  height: 48px;
  background-color: #7ac23c;
  border-radius: 4px 0 0 4px;
}
.drag_text {
  position: absolute;
  top: 0;
  width: 100%;
  font-size: 16px;
  color: #b5bac2;
  text-align: center;
  user-select: none;
}
</style>
