/**
 * agency模块
 */
import { PageLayout } from 'vue-el-web'
// 一个菜单对应一个父级路由
// 一个父级路由下面不允许存在多级children
// noRequireAuth：表示不需要进行登录验证，也不需要进行菜单权限验证
// menuRoute：表示需要进行登录验证，也需要进行菜单权限验证，但是路由切换不会再次校验登录信息
// noMenuRoute：表示需要进行登录验证，但不需要进行菜单权限验证，路由切换会再次校验登录信息
// 无：表示不需要进行登录验证，也不需要进行菜单权限验证，也表示该页面不能直接打开，需要通过路由跳转
const viewsTest = [
 /**
   * 检测评价记录
   */
 {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/technical/techServiceBasicDetail/detail',
        name: 'TechServiceBasicDetailIndex',
        component: () => import('@/views/agency/techServiceBasic/techServiceBasic/techServiceBasicDetail.vue'),
        meta: { menuRoute: true, title: '机构基础信息' },
      },
      {
        path: '/technical/townServiceBasicDetail/detail',
        name: 'TownServiceBasicDetailIndex',
        component: () => import('@/views/agency/techServiceBasic/techServiceBasic/townServiceBasicDetail.vue'),
        meta: { menuRoute: true, title: '机构基础信息' },
      },
      {
        path: '/technical/workplace_detection/detectionReportManagement',
        name: 'DetectionReportManagement',
        component: () => import('@/views/agency/workplace_detection/DetectionReportManagement.vue'),
        meta: { menuRoute: true, title: '检测评价记录' },
      },
      {
        path: '/system_user_management/service_organization/TechServiceBasicEdit',
        name: 'service_organization.TechServiceBasicEdit',
        component: () => import('@/views/agency/techServiceBasic/techServiceBasic/techEdit.vue'),
        meta: { title: '添加' },
      },
      {
        path: '/system_user_management/TownServiceBasicEdit',
        name: 'TownServiceBasicEdit',
        component: () => import('@/views/agency/techServiceBasic/techServiceBasic/townEdit.vue'),
        meta: { title: '添加' },
      },
      {
        path: '/technical/workplace_detection/DetectionReportBasicInfoEdit',
        name: 'DetectionReportBasicInfoEdit',
        component: () => import('@/views/agency/workplace_detection/DetectionReportBasicInfoEdit.vue'),
        meta: { menuRoute: true, title: '简易登记' },
      },
      {
        path: '/technical/workplace_detection/DetectionReportBasicInfoDetail',
        name: 'DetectionReportInfoDetail',
        component: () => import('@/views/agency/workplace_detection/DetectionReportBasicInfoDetail.vue'),
        meta: { menuRoute: true, title: '详情' },
      },
      {
        path: '/technical/workplace_detection/resultRegistration/DetectionReportResultBasicInfoEdit',
        name: 'DetectionReportResultBasicInfoEdit',
        component: () => import('@/views/agency/workplace_detection/resultRegistration/DetectionReportResultBasicInfoEdit.vue'),
        meta: { menuRoute: true, title: '基本信息' },
      },

      {
        path: '/technical/workplace_detection/resultRegistration/DetectionReportDetectionResult',
        name: 'DetectionReportDetectionResult',
        component: () => import('@/views/agency/workplace_detection/resultRegistration/DetectionReportDetectionResult.vue'),
        meta: { menuRoute: true, title: '检测结果' },
      },
      {
        path: '/technical/workplace_detection/resultRegistration/DetectionReportDetectionResultAndAdvice',
        name: 'DetectionReportDetectionResultAndAdvice',
        component: () => import('@/views/agency/workplace_detection/resultRegistration/DetectionReportDetectionResultAndAdvice.vue'),
        meta: { menuRoute: true, title: '结果登记' },
      },
      {
        path: '/technical/workplace_detection/resultRegistration/DetectionReportConclusion',
        name: 'DetectionReportConclusion',
        component: () => import('@/views/agency/workplace_detection/resultRegistration/DetectionReportConclusion.vue'),
        meta: { menuRoute: true, title: '检测结论及建议' },
      },
      {
        path: '/technical/workplace_detection/resultRegistration/ServiceAgreementManagement',
        name: 'DetectionReportConclusion',
        component: () => import('@/views/agency/workplace_detection/ServiceAgreementManagement.vue'),
        meta: { menuRoute: true, title: '服务记录管理' },
      },
    ],
  },

    /**
   * 资质管理
   */
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/qualification_manage/QualificationManage',
        name: 'QualificationManage',
        component: () => import('@/views/agency/qualification_manage/QualificationManage.vue'),
        meta: { menuRoute: true, title: '资质管理' },
      },
      {
        path: '/qualification_manage/TechServiceBasicEdit',
        name: 'TechServiceBasicEdit',
        component: () => import('@/views/agency/qualification_manage/TechServiceBasicEdit.vue'),
        meta: { menuRoute: true, title: '编辑' },
      },
    ],
  },
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/qualification_manage/helper',
        name: 'helper',
        component: () => import('@/views/agency/helper/index.vue'),
        meta: { menuRoute: true, title: '帮扶企业管理' },
      },
    ],
  },

]

export default viewsTest
