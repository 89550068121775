export default {
  mounted() {
    // 根据配置设置头部左侧logo
    if (this.$options.name === 'DefaultLayout') {
      this.$nextTick(() => {
        let title = document.getElementsByClassName('header-title')[0]
        if (title) {
          let imgNode = title.children[0]
          this.$SimpleCodeTools.getConfig(data => {
            this.$store.commit('INIT_CONFIG', data)
            imgNode.src = this.$store.state.file + data.fileMap[3][0].annexPath
            let linkTag = window.top.document.getElementById('linkico')
            let mstitle = window.top.document.getElementById('mstitle')
            if (linkTag) {
              linkTag.href = this.file + data.fileMap[4].annexPath
            }
            if (mstitle) {
              mstitle.innerText = data.systemName
            }
          })
        }
      })
    }
  },
}
