//   引入示例     import AxxxBxxx from '../components/business/模块文件夹/axxx-bxxx'
//   文件夹命名以 '-'连接   组件命名以驼峰AxxxBxxx命名
import PortalHead from '../components/portal/portalHead.vue'
import PortalFooter from '../components/portal/portalFooter.vue'
import SliderCheck from './layout/slider/check/Index.vue'
import CustomDropdown from './business/CustomDropdown.vue'
import AttachmenUpload from './business/AttachmenUpload.vue'
const components = [PortalHead, PortalFooter, SliderCheck, CustomDropdown, AttachmenUpload]

export default {
  install(Vue) {
    components.map(component => {
      Vue.component(component.name, component)
    })
  },
}
