<template>
  <div :class="fileClass">
    <el-form-item :label-width="labelwidth" :label="label" :prop="fileProp" :rules="rules">
      <file-upload v-show="!ifDetail&&fileList.length<limit" ref="fileUpload" :accept="accept" @showFileList="ShowFileList" :limit="limit" @success="fileUploadSuccess" :size="size" :sizeInfo="sizeInfo">
        <el-button v-if="ifUploadButton" class="zwx-button zwx-button-icontext-26" icon="el-icon-upload2">上传</el-button>
        <slot></slot>
      </file-upload>
      <br v-if="ifUploadButton && !ifDetail&&fileList.length<limit" />
      <div class="show-file" v-if="$zwxBase.verifyIsNotBlank(fileList)">
        <div v-for="(item, index) in fileList" :key="index" style="width:fit-content" @mouseenter="mouseenter(item)" @mouseleave="mouseleave(item)">
          <i :class="getPrefix(item)" style="margin-right:3px;"></i>
          <el-button class="zwx-button zwx-button-text-26" @click="openFilePreview(fileList, index)">{{ item.fileName || '查看附件' }}</el-button>
          <el-button v-if="!ifDetail&&!item.fileName" class="zwx-button zwx-button-text-26" type="danger" @click="deletionPicFile(index)">删除</el-button>
          <span v-if="!ifDetail&&item.fileName">
            <span class="el-upload-list__item check" :style="item.check ? 'margin-left:5px;display:none;' : 'margin-left:5px;cursor:pointer'">
              <i class="el-icon-upload-success el-icon-circle-check"></i>
            </span>
            <span class="check" :style="item.check ? 'margin-left:5px;cursor:pointer' : 'margin-left:5px;display:none;'">
              <i class="el-icon-close" @click="deletionPicFile(index, 1)"></i>
            </span>
          </span>
        </div>
      </div>
    </el-form-item>
    <file-preview ref="filePreview" />
  </div>
</template>
<script>
export default {
  name: 'AttachmenUpload',
  model: {
    prop: 'fileList',
    event: 'updateFileList',
  },
  props: {
    fileList: {
      type: Array,
      default: () => [],
    },
    fileClass: {
      type: String,
      default: '',
    },
    ifDetail: { type: Boolean, default: false },
    ifUploadButton: { type: Boolean, default: true },
    labelwidth: { type: String, default: '82px' },
    label: { type: String, default: '附件上传：' },
    rules: { type: String, default: undefined },
    fileProp: { type: String, default: '' },
    accept: {
      type: String,
      default: '.png,.jpeg,.jpg,.pdf,.JPG,.JPEG,.PNG,.PDF',
    },
    limit: { type: Number, default: 1 },
    size: {
      type: Number,
      default: 50 * 1024 * 1024,
    },
    sizeInfo: {
      type: String,
      default: '50M',
    },
    //何处使用   1 系统内   2系统外（官网）
    useType: { String, default: 1 },
    folder: {
      type: String,
      default: '/default',
    },
  },
  computed: {
  },
  data() {
    return {
      api: this.$store.state.api,
    }
  },
  mounted() {
  },
  methods: {
    ShowFileList(showList) {
      showList.forEach(item => {
        let flag = this.fileList.some(e => e.fileName == item)
        if (flag) {
          return
        }
        this.fileList.push({
          fileName: item,
          fileIconShow: this.$zwxBase.dealfilePrefix(item),
          check: false,
        })
      })
      if (showList && showList.length > 0) {
        this.generateFilesUid()
      }
      this.$emit('showFileListAfter')
    },
    generateFilesUid() {
      this.$emit('loading', true)
      let data = {
        folder: this.folder,
      }
      this.$system.get(this.api + '/systematic/getAnnexUploadUid-0', data, false, false, data => {
        if (data.type === '00') {
          this.$refs.fileUpload.submit(data.annexUploadUid)
          this.$emit('loading', false)
        } else {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      })
    },
    fileUploadSuccess(fileList) {
      // 文件集合
      if (this.$zwxBase.verifyIsNotBlank(this.fileList) && this.$zwxBase.verifyIsNotBlank(fileList)) {
        for (let i in this.fileList) {
          for (let j in fileList) {
            if (this.fileList[i].fileName === fileList[j].fileName) {
              // this.fileList[i].filePath = fileList[j].filePath
              this.$set(this.fileList[i], 'filePath', fileList[j].filePath)
              this.fileList[i].fileName = fileList[j].fileName
            }
          }
        }
      }
      this.$emit('updateFileList', this.fileList)
    },
    deletionPicFile(index) {
      let fileName = this.fileList[index].fileName
      let filePath = this.fileList[index].filePath
      this.fileList.splice(index, 1)
      if (this.$zwxBase.verifyIsBlank(filePath)) {
        this.$refs.fileUpload.deleteIndex(index)
      }
      let fileList = this.$refs.fileUpload.showFileList || []
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs.fileUpload.deleteIndex(j)
            fileList = this.$refs.fileUpload.showFileList || []
            continue
          }
        }
      }
      this.$emit('updateFileList', this.fileList)
    },
    getPrefix(file) {
      if (!file.fileName) return ''
      return this.$zwxBase.dealfilePrefix(file.fileName)
    },
    mouseenter(item) {
      item.check = true
      this.$forceUpdate()
    },
    mouseleave(item) {
      item.check = false
      this.$forceUpdate()
    },
    openFilePreview(fileList, index) {
      if (this.useType == 1) {
        top.postMessage(
          {
            handlerType: 'previewFile',
            params: {
              fileList: fileList,
              index: index,
            },
          },
          '*'
        )
      } else {
        this.$nextTick(function () {
          this.$refs.filePreview.initPreviewFile(fileList, index)
        })
      }
    },
  },
}
</script>
<style lang="less" scoped></style>
