import Vue from 'vue'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

// 此处开始import自定义组件
import components from './components/Index'
import VueElWeb from 'vue-el-web'
import 'vue-el-web/lib/vue-el-web.css'
import 'vue-el-web/lib/style/common.less'

// 此处开始import自定义JS
import CommonTools from './assets/js/common.js'
import SimpleCodeTools from './assets/js/simpleCodeTools.js'
import FormatData from './assets/js/formatData.js'
import VueBMap from 'vue-bmap-gl'
Vue.use(VueBMap)
console.log(VueBMap, 'VueBMap')
VueBMap.initBMapApiLoader({
  ak: 'f94hFK7e1qBEXGemWZDQSIXnRAX3h4Hs',
  v: '1.0',
})
// 挂载到vue实例中
Vue.use(VueAxios, axios)
Vue.use(ElementUI)
// 此处开始Vue.use自定义组件
Vue.use(components)
Vue.use(VueElWeb)

Vue.prototype.$CommonTools = CommonTools
Vue.prototype.$SimpleCodeTools = SimpleCodeTools
Vue.prototype.$FormatData = FormatData
var vm = new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
})

window.vm = vm
