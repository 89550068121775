import { PageLayout, UnderDevelopment } from 'vue-el-web'
/**
 * 以项目命名
 */
import viewsTest1 from './business'
import viewsTest2 from './agency'
import viewsTest3 from './employer'
import viewsTest4 from './portal/test'
import viewsTest5 from './system'
// 默认路由地址
const plugRoutes = [
  //门户首页
  // {
  //   path: '/',
  //   name: 'HomePage',
  //   component: () => import('../views/portal/home-page/homePage.vue'),
  //   meta: { noRequireAuth: true },
  // },
]
const viewRoures = plugRoutes.concat(viewsTest1, viewsTest2, viewsTest3, viewsTest4,viewsTest5)

// 一个菜单对应一个父级路由
// 一个父级路由下面不允许存在多级children
// noRequireAuth：表示不需要进行登录验证，也不需要进行菜单权限验证
// menuRoute：表示需要进行登录验证，也需要进行菜单权限验证，但是路由切换不会再次校验登录信息
// noMenuRoute：表示需要进行登录验证，但不需要进行菜单权限验证，路由切换会再次校验登录信息
// 无：表示不需要进行登录验证，也不需要进行菜单权限验证，也表示该页面不能直接打开，需要通过路由跳转
const views = viewRoures.concat([
  {
    path: '/emptyPage',
    name: 'UnderDevelopment',
    component: UnderDevelopment,
    meta: { noRequireAuth: true },
  },

  //菜单路由示例
  // {
  //   path: '/',
  //   component: PageLayout,
  //   children: [
  //     {
  //       path: '/portal/模块名/testList',
  //       name: 'TestList',
  //       component: () => import('../../views/portal/模块名/testList.vue'),
  //       meta: {menuRoute: true },
  //     },
  //     {
  //       path: '/portal/模块名/testEdit',
  //       name: 'TestEdit',
  //       component: () => import('../../views/portal/模块名/testEdit.vue'),
  //       meta: {menuRoute: true ,title:'编辑'},
  //     },
  //     {
  //       path: '/portal/模块名/testDetail',
  //       name: 'TestDetail',
  //       component: () => import('../../views/portal/模块名/testDetail.vue'),
  //       meta: {menuRoute: true ,title:'详情'},
  //     },
  //   ],
  // },
])

export default views
