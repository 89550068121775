<template>
  <div class="zwx-exam-record-detail-index">
    <div style="width: 100%;display: flex;justify-content: center;">
      <div class="zwx-exam-record-main">
        <zwx-scrollbar-y style="height: 100%;width: calc(100% - 328px);background: #ffffff" ref="searchScrollbar">
          <div class="zwx-exam-record-main-left" v-if="examRecordDetailForm.scoreMode != 0">
            <base-headline title="单选题" class="no-top" style="padding-right: 2px" v-if="$zwxBase.verifyIsNotBlank(examRecordDetailForm.radioQuestionList)" />
            <div class="question-body" v-if="$zwxBase.verifyIsNotBlank(examRecordDetailForm.radioQuestionList)">
              <template v-for="(item, i) in examRecordDetailForm.radioQuestionList">
                <div class="question">
                  <div class="question-head">
                    <div class="question-num">{{ item.serialNumber }}、</div>
                    <div class="question-title">
                      {{ item.tbTrainingTopicExp.topicContent }}
                    </div>
                  </div>
                  <div class="question-pic" v-if="$zwxBase.verifyIsNotBlank(item.tbTrainingTopicExp.picPath)" @click="openFilePreview(item.tbTrainingTopicExp.picPath)">
                    <img :src="$store.state.file + item.tbTrainingTopicExp.picPath" style="width: 100%;height: 100%" />
                  </div>
                  <div class="question-options-column" v-if="$zwxBase.verifyIsBlank(item.tbTrainingTopicExp.optionLayout) || item.tbTrainingTopicExp.optionLayout === 1">
                    <template v-for="opt in item.tbTrainingTopicExp.trainingOptionList">
                      <div v-if="item.optionValue === opt.rid && opt.ifAnswer === true" class="choose-right">
                        <el-radio class="zwx-radio" :key="opt.rid" :label="opt.rid" v-model="item.optionValue" disabled>{{ opt.optionCode }} {{ opt.optionContent }}</el-radio>
                      </div>
                      <div v-if="item.optionValue === opt.rid && opt.ifAnswer === false" class="choose-error">
                        <el-radio class="zwx-radio" :key="opt.rid" :label="opt.rid" v-model="item.optionValue" disabled>{{ opt.optionCode }} {{ opt.optionContent }}</el-radio>
                      </div>
                      <div v-if="item.optionValue !== opt.rid">
                        <el-radio class="zwx-radio" :key="opt.rid" :label="opt.rid" v-model="item.optionValue" disabled>{{ opt.optionCode }} {{ opt.optionContent }}</el-radio>
                      </div>
                    </template>
                  </div>
                  <div class="question-options-default" v-if="item.tbTrainingTopicExp.optionLayout === 2">
                    <template v-for="opt in item.tbTrainingTopicExp.trainingOptionList">
                      <div v-if="item.optionValue === opt.rid && opt.ifAnswer === true">
                        <img style="height: 90px;margin-bottom: 20px;margin-right: 60px;padding-left: 28px" :src="$store.state.file + opt.picPath" @click="openFilePreview(opt.picPath)" v-if="$zwxBase.verifyIsNotBlank(opt.picPath)" />
                        <div style="margin-left: 28px" class="choose-right">
                          <el-radio class="zwx-radio" :key="opt.rid" :label="opt.rid" v-model="item.optionValue" disabled>{{ opt.optionCode }} {{ opt.optionContent }}</el-radio>
                        </div>
                      </div>
                      <div v-if="item.optionValue === opt.rid && opt.ifAnswer === false">
                        <img style="height: 90px;margin-bottom: 20px;margin-right: 60px;padding-left: 28px" :src="$store.state.file + opt.picPath" @click="openFilePreview(opt.picPath)" v-if="$zwxBase.verifyIsNotBlank(opt.picPath)" />
                        <div style="margin-left: 28px" class="choose-error">
                          <el-radio class="zwx-radio" :key="opt.rid" :label="opt.rid" v-model="item.optionValue" disabled>{{ opt.optionCode }} {{ opt.optionContent }}</el-radio>
                        </div>
                      </div>
                      <div v-if="item.optionValue !== opt.rid">
                        <img style="height: 90px;margin-bottom: 20px;margin-right: 60px;padding-left: 28px" :src="$store.state.file + opt.picPath" @click="openFilePreview(opt.picPath)" v-if="$zwxBase.verifyIsNotBlank(opt.picPath)" />
                        <div style="margin-left: 28px">
                          <el-radio class="zwx-radio" :key="opt.rid" :label="opt.rid" v-model="item.optionValue" disabled>{{ opt.optionCode }} {{ opt.optionContent }}</el-radio>
                        </div>
                      </div>
                    </template>
                  </div>
                  <div class="question-analysis" @click.stop="changeAnalysis('radioQuestionList', i)">
                    试题解析
                    <i class="el-icon-arrow-down" v-show="$zwxBase.verifyIsBlank(item.ifShowAnalysis) || item.ifShowAnalysis === false"></i>
                    <i class="el-icon-arrow-up" v-show="item.ifShowAnalysis === true"></i>
                  </div>
                  <div class="question-analysis-text" style="color: #1DC482;" v-show="item.ifShowAnalysis === true">正确选项：{{ getOptionAnswer(item.tbTrainingTopicExp.trainingOptionList) }}</div>
                  <div class="question-analysis-text" v-show="item.ifShowAnalysis === true">
                    {{ $zwxBase.verifyIsNotBlank(item) ? item.tbTrainingTopicExp.topicAnalysis : '' }}
                  </div>
                </div>
                <div class="divider-line" v-if="i < examRecordDetailForm.radioQuestionList.length - 1"></div>
              </template>
            </div>
            <base-headline title="多选题" style="padding-right: 2px" v-if="$zwxBase.verifyIsNotBlank(examRecordDetailForm.checkQuestionList)" />
            <div class="question-body" v-if="$zwxBase.verifyIsNotBlank(examRecordDetailForm.checkQuestionList)">
              <template v-for="(item, i) in examRecordDetailForm.checkQuestionList">
                <div class="question">
                  <div class="question-head">
                    <div class="question-num">{{ item.serialNumber }}、</div>
                    <div class="question-title">
                      {{ item.tbTrainingTopicExp.topicContent }}
                    </div>
                  </div>
                  <div class="question-pic" v-if="$zwxBase.verifyIsNotBlank(item.tbTrainingTopicExp.picPath)" @click="openFilePreview(item.tbTrainingTopicExp.picPath)">
                    <img :src="$store.state.file + item.tbTrainingTopicExp.picPath" style="width: 100%;height: 100%" />
                  </div>
                  <div class="question-multi-options-column" v-if="$zwxBase.verifyIsBlank(item.tbTrainingTopicExp.optionLayout) || item.tbTrainingTopicExp.optionLayout === 1">
                    <el-checkbox-group class="zwx-checkbox-group" v-model="item.optionValue" disabled>
                      <template v-for="opt in item.tbTrainingTopicExp.trainingOptionList">
                        <div class="multi-option choose-right" v-if="$zwxBase.verifyIsNotBlank(item.optionValue) && item.optionValue.indexOf(opt.rid) != -1 && opt.ifAnswer === true">
                          <el-checkbox :label="opt.rid" :key="opt.rid">{{ opt.optionCode }} {{ opt.optionContent }}</el-checkbox>
                        </div>
                        <div class="multi-option choose-error" v-if="$zwxBase.verifyIsNotBlank(item.optionValue) && item.optionValue.indexOf(opt.rid) != -1 && opt.ifAnswer === false">
                          <el-checkbox :label="opt.rid" :key="opt.rid">{{ opt.optionCode }} {{ opt.optionContent }}</el-checkbox>
                        </div>
                        <div class="multi-option" v-if="$zwxBase.verifyIsBlank(item.optionValue) || item.optionValue.indexOf(opt.rid) == -1">
                          <el-checkbox :label="opt.rid" :key="opt.rid">{{ opt.optionCode }} {{ opt.optionContent }}</el-checkbox>
                        </div>
                      </template>
                    </el-checkbox-group>
                  </div>
                  <div class="question-multi-options-default" v-if="item.tbTrainingTopicExp.optionLayout === 2">
                    <el-checkbox-group class="zwx-checkbox-group" v-model="item.optionValue" disabled>
                      <div class="cross-layout" v-for="opt in item.tbTrainingTopicExp.trainingOptionList">
                        <img v-if="opt.picPath" style="height: 90px;margin-bottom: 20px" :src="$store.state.file + opt.picPath" />
                        <div class="multi-option choose-right" v-if="$zwxBase.verifyIsNotBlank(item.optionValue) && $zwxBase.verifyIsNotBlank(item.optionValue.filter(e => e === opt.rid)) && opt.ifAnswer === true">
                          <el-checkbox :label="opt.rid" :key="opt.rid">{{ opt.optionCode }} {{ opt.optionContent }}</el-checkbox>
                        </div>
                        <div class="multi-option choose-error" v-if="$zwxBase.verifyIsNotBlank(item.optionValue) && $zwxBase.verifyIsNotBlank(item.optionValue.filter(e => e === opt.rid)) && opt.ifAnswer === false">
                          <el-checkbox :label="opt.rid" :key="opt.rid">{{ opt.optionCode }} {{ opt.optionContent }}</el-checkbox>
                        </div>
                        <div class="multi-option" v-if="$zwxBase.verifyIsBlank(item.optionValue) || $zwxBase.verifyIsBlank(item.optionValue.filter(e => e === opt.rid))">
                          <el-checkbox :label="opt.rid" :key="opt.rid">{{ opt.optionCode }} {{ opt.optionContent }}</el-checkbox>
                        </div>
                      </div>
                    </el-checkbox-group>
                  </div>
                  <div class="question-analysis" @click.stop="changeAnalysis('checkQuestionList', i)">
                    试题解析
                    <i class="el-icon-arrow-down" v-show="$zwxBase.verifyIsBlank(item.ifShowAnalysis) || item.ifShowAnalysis === false"></i>
                    <i class="el-icon-arrow-up" v-show="item.ifShowAnalysis === true"></i>
                  </div>
                  <div class="question-analysis-text" style="color: #1DC482;" v-show="item.ifShowAnalysis === true">正确选项：{{ getOptionAnswer(item.tbTrainingTopicExp.trainingOptionList) }}</div>
                  <div class="question-analysis-text" v-show="item.ifShowAnalysis === true">
                    {{ $zwxBase.verifyIsNotBlank(item) ? item.tbTrainingTopicExp.topicAnalysis : '' }}
                  </div>
                </div>
                <div class="divider-line" v-if="i < examRecordDetailForm.checkQuestionList.length - 1"></div>
              </template>
            </div>
            <base-headline title="判断题" style="padding-right: 2px" v-if="$zwxBase.verifyIsNotBlank(examRecordDetailForm.judgeQuestionList)" />
            <div class="question-body" v-if="$zwxBase.verifyIsNotBlank(examRecordDetailForm.judgeQuestionList)">
              <template v-for="(item, i) in examRecordDetailForm.judgeQuestionList">
                <div class="question">
                  <div class="question-head">
                    <div class="question-num">{{ item.serialNumber }}、</div>
                    <div class="question-title">
                      {{ item.tbTrainingTopicExp.topicContent }}
                    </div>
                  </div>
                  <div class="question-pic" v-if="$zwxBase.verifyIsNotBlank(item.tbTrainingTopicExp.picPath)" @click="openFilePreview(item.tbTrainingTopicExp.picPath)">
                    <img :src="$store.state.file + item.tbTrainingTopicExp.picPath" style="width: 100%;height: 100%" />
                  </div>
                  <div class="question-options-column" v-if="$zwxBase.verifyIsBlank(item.tbTrainingTopicExp.optionLayout) || item.tbTrainingTopicExp.optionLayout === 1">
                    <template v-for="opt in item.tbTrainingTopicExp.trainingOptionList">
                      <div v-if="item.optionValue === opt.rid && opt.ifAnswer === true" class="choose-right">
                        <el-radio class="zwx-radio" :key="opt.rid" :label="opt.rid" v-model="item.optionValue" disabled>{{ opt.optionCode }} {{ opt.optionContent }}</el-radio>
                      </div>
                      <div v-if="item.optionValue === opt.rid && opt.ifAnswer === false" class="choose-error">
                        <el-radio class="zwx-radio" :key="opt.rid" :label="opt.rid" v-model="item.optionValue" disabled>{{ opt.optionCode }} {{ opt.optionContent }}</el-radio>
                      </div>
                      <div v-if="item.optionValue !== opt.rid">
                        <el-radio class="zwx-radio" :key="opt.rid" :label="opt.rid" v-model="item.optionValue" disabled>{{ opt.optionCode }} {{ opt.optionContent }}</el-radio>
                      </div>
                    </template>
                  </div>
                  <div class="question-options-default" v-if="item.tbTrainingTopicExp.optionLayout === 2">
                    <template v-for="opt in item.tbTrainingTopicExp.trainingOptionList">
                      <div v-if="item.optionValue === opt.rid && opt.ifAnswer === true">
                        <img style="height: 90px;margin-bottom: 20px;margin-right: 60px;padding-left: 28px" :src="$store.state.file + opt.picPath" @click="openFilePreview(opt.picPath)" v-if="$zwxBase.verifyIsNotBlank(opt.picPath)" />
                        <div style="margin-left: 28px" class="choose-right">
                          <el-radio class="zwx-radio" :key="opt.rid" :label="opt.rid" v-model="item.optionValue" disabled>{{ opt.optionCode }} {{ opt.optionContent }}</el-radio>
                        </div>
                      </div>
                      <div v-if="item.optionValue === opt.rid && opt.ifAnswer === false">
                        <img style="height: 90px;margin-bottom: 20px;margin-right: 60px;padding-left: 28px" :src="$store.state.file + opt.picPath" @click="openFilePreview(opt.picPath)" v-if="$zwxBase.verifyIsNotBlank(opt.picPath)" />
                        <div style="margin-left: 28px" class="choose-error">
                          <el-radio class="zwx-radio" :key="opt.rid" :label="opt.rid" v-model="item.optionValue" disabled>{{ opt.optionCode }} {{ opt.optionContent }}</el-radio>
                        </div>
                      </div>
                      <div v-if="item.optionValue !== opt.rid">
                        <img style="height: 90px;margin-bottom: 20px;margin-right: 60px;padding-left: 28px" :src="$store.state.file + opt.picPath" @click="openFilePreview(opt.picPath)" v-if="$zwxBase.verifyIsNotBlank(opt.picPath)" />
                        <div style="margin-left: 28px">
                          <el-radio class="zwx-radio" :key="opt.rid" :label="opt.rid" v-model="item.optionValue" disabled>{{ opt.optionCode }} {{ opt.optionContent }}</el-radio>
                        </div>
                      </div>
                    </template>
                  </div>
                  <div class="question-analysis" @click.stop="changeAnalysis('judgeQuestionList', i)">
                    试题解析
                    <i class="el-icon-arrow-down" v-show="$zwxBase.verifyIsBlank(item.ifShowAnalysis) || item.ifShowAnalysis === false"></i>
                    <i class="el-icon-arrow-up" v-show="item.ifShowAnalysis === true"></i>
                  </div>
                  <div class="question-analysis-text" style="color: #1DC482;" v-show="item.ifShowAnalysis === true">正确选项：{{ getOptionAnswer(item.tbTrainingTopicExp.trainingOptionList) }}</div>
                  <div class="question-analysis-text" v-show="item.ifShowAnalysis === true">
                    {{ $zwxBase.verifyIsNotBlank(item) ? item.tbTrainingTopicExp.topicAnalysis : '' }}
                  </div>
                </div>
                <div class="divider-line" v-if="i < examRecordDetailForm.judgeQuestionList.length - 1"></div>
              </template>
            </div>
          </div>
          <div class="zwx-exam-record-main-left" v-if="examRecordDetailForm.scoreMode == 0">
            <div class="question-body" v-if="$zwxBase.verifyIsNotBlank(examRecordDetailForm.examTopicDeailList)">
              <template v-for="(item, i) in examRecordDetailForm.examTopicDeailList">
                <div class="question">
                  <div class="question-head">
                    <div class="question-num">{{ item.serialNumber }}、</div>
                    <div class="question-title">
                      {{ item.tbTrainingTopicExp.topicContent }}
                    </div>
                  </div>
                  <div class="question-pic" v-if="$zwxBase.verifyIsNotBlank(item.tbTrainingTopicExp.picPath)" @click="openFilePreview(item.tbTrainingTopicExp.picPath)">
                    <img :src="$store.state.file + item.tbTrainingTopicExp.picPath" style="width: 100%;height: 100%" />
                  </div>
                  <!--       竖向多选           -->
                  <div class="question-multi-options-column" v-if="($zwxBase.verifyIsBlank(item.tbTrainingTopicExp.optionLayout) || item.tbTrainingTopicExp.optionLayout === 1) && item.tbTrainingTopicExp.topicType == 2">
                    <el-checkbox-group class="zwx-checkbox-group" v-model="item.optionValue" disabled>
                      <template v-for="opt in item.tbTrainingTopicExp.trainingOptionList">
                        <div class="multi-option choose-right" v-if="$zwxBase.verifyIsNotBlank(item.optionValue) && item.optionValue.indexOf(opt.rid) != -1 && opt.ifAnswer === true">
                          <el-checkbox :label="opt.rid" :key="opt.rid">{{ opt.optionCode }} {{ opt.optionContent }}</el-checkbox>
                        </div>
                        <div class="multi-option choose-error" v-if="$zwxBase.verifyIsNotBlank(item.optionValue) && item.optionValue.indexOf(opt.rid) != -1 && opt.ifAnswer === false">
                          <el-checkbox :label="opt.rid" :key="opt.rid">{{ opt.optionCode }} {{ opt.optionContent }}</el-checkbox>
                        </div>
                        <div class="multi-option" v-if="$zwxBase.verifyIsBlank(item.optionValue) || item.optionValue.indexOf(opt.rid) == -1">
                          <el-checkbox :label="opt.rid" :key="opt.rid">{{ opt.optionCode }} {{ opt.optionContent }}</el-checkbox>
                        </div>
                      </template>
                    </el-checkbox-group>
                  </div>
                  <!--       竖向单选           -->
                  <div class="question-options-column" v-if="($zwxBase.verifyIsBlank(item.tbTrainingTopicExp.optionLayout) || item.tbTrainingTopicExp.optionLayout === 1) && item.tbTrainingTopicExp.topicType != 2">
                    <template v-for="opt in item.tbTrainingTopicExp.trainingOptionList">
                      <div v-if="item.optionValue === opt.rid && opt.ifAnswer === true" class="choose-right">
                        <el-radio class="zwx-radio" :key="opt.rid" :label="opt.rid" v-model="item.optionValue" disabled>{{ opt.optionCode }} {{ opt.optionContent }}</el-radio>
                      </div>
                      <div v-if="item.optionValue === opt.rid && opt.ifAnswer === false" class="choose-error">
                        <el-radio class="zwx-radio" :key="opt.rid" :label="opt.rid" v-model="item.optionValue" disabled>{{ opt.optionCode }} {{ opt.optionContent }}</el-radio>
                      </div>
                      <div v-if="item.optionValue !== opt.rid">
                        <el-radio class="zwx-radio" :key="opt.rid" :label="opt.rid" v-model="item.optionValue" disabled>{{ opt.optionCode }} {{ opt.optionContent }}</el-radio>
                      </div>
                    </template>
                  </div>
                  <!--       横向多选           -->
                  <div class="question-multi-options-default" v-if="item.tbTrainingTopicExp.optionLayout === 2 && item.tbTrainingTopicExp.topicType == 2">
                    <el-checkbox-group class="zwx-checkbox-group" v-model="item.optionValue" disabled>
                      <div class="cross-layout" v-for="opt in item.tbTrainingTopicExp.trainingOptionList">
                        <img v-if="opt.picPath" style="height: 90px;margin-bottom: 20px" :src="$store.state.file + opt.picPath" />
                        <div class="multi-option choose-right" v-if="$zwxBase.verifyIsNotBlank(item.optionValue) && $zwxBase.verifyIsNotBlank(item.optionValue.filter(e => e === opt.rid)) && opt.ifAnswer === true">
                          <el-checkbox :label="opt.rid" :key="opt.rid">{{ opt.optionCode }} {{ opt.optionContent }}</el-checkbox>
                        </div>
                        <div class="multi-option choose-error" v-if="$zwxBase.verifyIsNotBlank(item.optionValue) && $zwxBase.verifyIsNotBlank(item.optionValue.filter(e => e === opt.rid)) && opt.ifAnswer === false">
                          <el-checkbox :label="opt.rid" :key="opt.rid">{{ opt.optionCode }} {{ opt.optionContent }}</el-checkbox>
                        </div>
                        <div class="multi-option" v-if="$zwxBase.verifyIsBlank(item.optionValue) || $zwxBase.verifyIsBlank(item.optionValue.filter(e => e === opt.rid))">
                          <el-checkbox :label="opt.rid" :key="opt.rid">{{ opt.optionCode }} {{ opt.optionContent }}</el-checkbox>
                        </div>
                      </div>
                    </el-checkbox-group>
                  </div>
                  <!--       横向单选           -->
                  <div class="question-options-default" v-if="item.tbTrainingTopicExp.optionLayout === 2 && item.tbTrainingTopicExp.topicType != 2">
                    <template v-for="opt in item.tbTrainingTopicExp.trainingOptionList">
                      <div v-if="item.optionValue === opt.rid && opt.ifAnswer === true">
                        <img style="height: 90px;margin-bottom: 20px;margin-right: 60px;padding-left: 28px" :src="$store.state.file + opt.picPath" @click="openFilePreview(opt.picPath)" v-if="$zwxBase.verifyIsNotBlank(opt.picPath)" />
                        <div style="margin-left: 28px" class="choose-right">
                          <el-radio class="zwx-radio" :key="opt.rid" :label="opt.rid" v-model="item.optionValue" disabled>{{ opt.optionCode }} {{ opt.optionContent }}</el-radio>
                        </div>
                      </div>
                      <div v-if="item.optionValue === opt.rid && opt.ifAnswer === false">
                        <img style="height: 90px;margin-bottom: 20px;margin-right: 60px;padding-left: 28px" :src="$store.state.file + opt.picPath" @click="openFilePreview(opt.picPath)" v-if="$zwxBase.verifyIsNotBlank(opt.picPath)" />
                        <div style="margin-left: 28px" class="choose-error">
                          <el-radio class="zwx-radio" :key="opt.rid" :label="opt.rid" v-model="item.optionValue" disabled>{{ opt.optionCode }} {{ opt.optionContent }}</el-radio>
                        </div>
                      </div>
                      <div v-if="item.optionValue !== opt.rid">
                        <img style="height: 90px;margin-bottom: 20px;margin-right: 60px;padding-left: 28px" :src="$store.state.file + opt.picPath" @click="openFilePreview(opt.picPath)" v-if="$zwxBase.verifyIsNotBlank(opt.picPath)" />
                        <div style="margin-left: 28px">
                          <el-radio class="zwx-radio" :key="opt.rid" :label="opt.rid" v-model="item.optionValue" disabled>{{ opt.optionCode }} {{ opt.optionContent }}</el-radio>
                        </div>
                      </div>
                    </template>
                  </div>
                  <div class="question-analysis" @click.stop="changeAnalysis('examTopicDeailList', i)">
                    试题解析
                    <i class="el-icon-arrow-down" v-show="$zwxBase.verifyIsBlank(item.ifShowAnalysis) || item.ifShowAnalysis === false"></i>
                    <i class="el-icon-arrow-up" v-show="item.ifShowAnalysis === true"></i>
                  </div>
                  <div class="question-analysis-text" style="color: #1DC482;" v-show="item.ifShowAnalysis === true">正确选项：{{ getOptionAnswer(item.tbTrainingTopicExp.trainingOptionList) }}</div>
                  <div class="question-analysis-text" v-show="item.ifShowAnalysis === true">
                    {{ $zwxBase.verifyIsNotBlank(item) ? item.tbTrainingTopicExp.topicAnalysis : '' }}
                  </div>
                </div>
                <div class="divider-line" v-if="i < examRecordDetailForm.examTopicDeailList.length - 1"></div>
              </template>
            </div>
          </div>
        </zwx-scrollbar-y>
        <div class="zwx-exam-record-main-right">
          <div class="course-name-text">{{ examRecordDetailForm.examName }}</div>
          <div class="exam-result-complete" v-if="examRecordDetailForm.ifComplete === true">
            <div class="complete-label">
              <i class="filled chis-icon-filled-completed" style="color: #1DC482;margin-right: 2px"></i>
              通过
            </div>
            <div class="exam-score-label">
              考试成绩
              <span class="exam-score-num">
                {{ examRecordDetailForm.examScore }}
                <span style="font-size: 16px">分</span>
              </span>
            </div>
          </div>
          <div class="exam-result-no-complete" v-if="examRecordDetailForm.ifComplete === false">
            <div class="no-complete-label">
              <i class="filled chis-icon-filled-failed" style="color:#EC4726;margin-right: 2px"></i>
              未通过
            </div>
            <div class="exam-score-label">
              考试成绩
              <span class="exam-score-num">
                {{ examRecordDetailForm.examScore }}
                <span style="font-size: 16px">分</span>
              </span>
            </div>
          </div>
          <div class="answer-sheet">
            <div class="answer-sheet-text">答题卡</div>
            <div class="sample-text">
              <div class="answer-tag" style="background: #1DC482"></div>
              <div class="tag-text">答对</div>
              <div class="answer-tag" style="background: #FA4A39"></div>
              <div class="tag-text">答错</div>
            </div>
          </div>
          <div class="answer-detail">
            <zwx-scrollbar-y style="height: 100%;" ref="answerScrollbar">
              <template v-for="item in examRecordDetailForm.answerList">
                <div class="answer-card" :style="item.ifCorrect !== true ? 'background:#FA4A39' : ''">
                  <span style="color: #ffffff;line-height: 22px">{{ item.serialNumber }}</span>
                </div>
              </template>
            </zwx-scrollbar-y>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExamRecordDetail',
  components: {},
  props: {},
  data() {
    return {
      api: this.$store.state.api,
      examRecordDetailForm: {
        rid: '',
        examId: '',
        examName: '',
        examScore: '',
        ifComplete: '',
        scoreMode: '',
        radioQuestionList: [],
        checkQuestionList: [],
        judgeQuestionList: [],
        examTopicDeailList: [], //全部题目
        answerList: [],
      },
    }
  },
  created() { },
  mounted() { },
  activated() {
    document.getElementsByClassName('zwx-border')[0].style.background = '#f5fafc'
    document.getElementsByClassName('breadcrumb')[0].style.border = 'none'
    document.getElementsByClassName('breadcrumb')[0].style.setProperty('width', '1220px', 'important')
    document.getElementsByClassName('breadcrumb')[0].style.margin = 'auto'
  },
  computed: {},
  methods: {
    // 答卷信息数据初始化
    ExamRecordDetailInit(data) {
      this.examRecordDetailForm.examName = data.examName
      this.examRecordDetailForm.rid = data.tdExamRecord.rid
      this.examRecordDetailForm.examId = data.tdExamRecord.examId
      this.examRecordDetailForm.examScore = data.tdExamRecord.examScore
      this.examRecordDetailForm.ifComplete = data.tdExamRecord.ifComplete
      this.examRecordDetailForm.scoreMode = data.tdExamRecord.examConfigExp.scoreMode
      if (this.examRecordDetailForm.scoreMode == 0) {
        this.examRecordDetailForm.examTopicDeailList = data.examTopicDeailList || []
        if (this.$zwxBase.verifyIsNotBlank(this.examRecordDetailForm.examTopicDeailList)) {
          this.examRecordDetailForm.examTopicDeailList.forEach(item => {
            if (this.$zwxBase.verifyIsNotBlank(item.tbTrainingTopicExp) && item.tbTrainingTopicExp.topicType == 2) {
              item.optionValue = item.optionValue.split(',')
            }
          })
        }
      } else {
        this.examRecordDetailForm.radioQuestionList = data.tdExamTopicDeailPackListSingle || []
        this.examRecordDetailForm.checkQuestionList = data.tdExamTopicDeailPackListMultiple || []
        if (this.$zwxBase.verifyIsNotBlank(this.examRecordDetailForm.checkQuestionList)) {
          this.examRecordDetailForm.checkQuestionList.forEach(item => {
            if (this.$zwxBase.verifyIsNotBlank(item.optionValue)) {
              item.optionValue = item.optionValue.split(',')
            }
          })
        }
        this.examRecordDetailForm.judgeQuestionList = data.tdExamTopicDeailPackListJudgment || []
      }
      this.examRecordDetailForm.answerList = data.topicList || []
    },
    changeAnalysis(listName, index) {
      if (listName === 'radioQuestionList') {
        if (this.$zwxBase.verifyIsBlank(this.examRecordDetailForm.radioQuestionList[index].ifShowAnalysis) || this.examRecordDetailForm.radioQuestionList[index].ifShowAnalysis === false) {
          this.$set(this.examRecordDetailForm.radioQuestionList[index], 'ifShowAnalysis', true)
        } else {
          this.$set(this.examRecordDetailForm.radioQuestionList[index], 'ifShowAnalysis', false)
        }
      }
      if (listName === 'checkQuestionList') {
        if (this.$zwxBase.verifyIsBlank(this.examRecordDetailForm.checkQuestionList[index].ifShowAnalysis) || this.examRecordDetailForm.checkQuestionList[index].ifShowAnalysis === false) {
          this.$set(this.examRecordDetailForm.checkQuestionList[index], 'ifShowAnalysis', true)
        } else {
          this.$set(this.examRecordDetailForm.checkQuestionList[index], 'ifShowAnalysis', false)
        }
      }
      if (listName === 'judgeQuestionList') {
        if (this.$zwxBase.verifyIsBlank(this.examRecordDetailForm.judgeQuestionList[index].ifShowAnalysis) || this.examRecordDetailForm.judgeQuestionList[index].ifShowAnalysis === false) {
          this.$set(this.examRecordDetailForm.judgeQuestionList[index], 'ifShowAnalysis', true)
        } else {
          this.$set(this.examRecordDetailForm.judgeQuestionList[index], 'ifShowAnalysis', false)
        }
      }
      if (listName === 'examTopicDeailList') {
        if (this.$zwxBase.verifyIsBlank(this.examRecordDetailForm.examTopicDeailList[index].ifShowAnalysis) || this.examRecordDetailForm.examTopicDeailList[index].ifShowAnalysis === false) {
          this.$set(this.examRecordDetailForm.examTopicDeailList[index], 'ifShowAnalysis', true)
        } else {
          this.$set(this.examRecordDetailForm.examTopicDeailList[index], 'ifShowAnalysis', false)
        }
      }
    },
    ifCheck(item, optId) {
      if (this.$zwxBase.verifyIsNotBlank(item.optionValue) && item.optionValue.filter(e => e === optId)) {
        return true
      } else {
        return false
      }
    },
    getOptionAnswer(trainingOptions) {
      if (this.$zwxBase.verifyIsNotBlank(trainingOptions)) {
        let arr = []
        trainingOptions.forEach(item => {
          if (item.ifAnswer === true) {
            arr.push(item.optionCode)
          }
        })
        return arr.join('、')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.zwx-exam-record-detail-index {
  background: #f5fafc;
  .zwx-exam-record-main {
    display: flex;
    background: #f5fafc;
    height: calc(100vh - 102px) !important;

    .zwx-exam-record-main-left {
      width: 892px;
      height: 100%;
      background: #ffffff;

      .question-body {
        padding: 0 32px 22px 32px;
      }

      .question {
        margin-top: 22px;

        .question-head {
          display: flex;
          flex: 1;

          .question-num {
            color: #3a485e;
            font-size: 14px;
            line-height: 20px;
            width: 28px;
            // text-align: end;
          }

          .question-title {
            width: calc(100% - 14px);
            color: #3a485e;
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 12px;
          }
        }

        .question-pic {
          width: 130px;
          height: 78px;
          margin-bottom: 12px;
          padding-left: 28px;
        }

        /deep/ .zwx-radio {
          color: #3a485e !important;
          height: auto !important;
          white-space: normal !important;
        }
        /deep/ .el-checkbox__input {
          white-space:normal !important;
        }

        /deep/ .el-checkbox__input.is-disabled + span.el-checkbox__label {
          color: #3a485e !important;
        }

        .question-options-column div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          // height: 34px;
          padding-right: 11px;
          padding-left: 28px;
          border-radius: 4px;
        }

        /deep/ .choose-error .zwx-radio.is-disabled > .el-radio__input.is-disabled + span.el-radio__label {
          color: rgba(250, 74, 57, 1) !important;
        }

        /deep/ .choose-right .zwx-radio.is-disabled > .el-radio__input.is-disabled + span.el-radio__label {
          color: rgba(29, 196, 130, 1) !important;
        }

        /deep/ .choose-error .el-checkbox__input.is-disabled + span.el-checkbox__label {
          color: rgba(250, 74, 57, 1) !important;
        }

        /deep/ .choose-right .el-checkbox__input.is-disabled + span.el-checkbox__label {
          color: rgba(29, 196, 130, 1) !important;
        }

        .question-options-default {
          display: flex;
          flex-wrap: wrap;
        }

        .question-options-default div {
          min-width: 90px;
          padding-right: 11px;
          border-radius: 4px;
        }

        .question-multi-options-column {
          /deep/ .zwx-checkbox-group {
            display: flex;
            flex-direction: column;
          }

          .multi-option {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // height: 34px;
            margin: 2px 0;
            line-height: 28px;
            padding-right: 11px;
            padding-left: 28px;
            border-radius: 4px;
          }
        }
        .question-multi-options-default {
          /deep/ .zwx-checkbox-group {
            display: flex;
            padding-left: 28px;
            flex-wrap: wrap;
          }

          .cross-layout {
            display: flex;
            flex-direction: column;
            margin-right: 60px;
            // width: 90px;

            .multi-option {
              display: flex;
              justify-content: space-between;
              padding-right: 11px;
            }
          }
        }

        .question-analysis {
          display: flex;
          align-items: center;
          color: #505b89;
          font-size: 14px;
          cursor: pointer;
          margin-top: 16px;
          margin-left: 30px;
        }

        .question-analysis:hover {
          color: #4969d9;
        }

        .question-analysis-text {
          color: #3a485e;
          font-size: 14px;
          line-height: 22px;
          margin-top: 12px;
          padding-left: 28px;
        }

        .answer-correct {
          color: #04945d;
          font-size: 14px;
          line-height: 14px;
        }

        .answer-error {
          color: #f0421f;
          font-size: 14px;
          line-height: 14px;
        }
      }

      .divider-line {
        border-bottom: 1px solid #e1e5f4;
        margin-top: 24px;
        margin-right: 32px;
      }
    }

    .zwx-exam-record-main-right {
      width: 304px;
      background: #ffffff;
      margin-left: 24px;

      .course-name-text {
        color: #3a485e;
        font-size: 16px;
        line-height: 22px;
        padding: 16px 0 16px 16px;
      }

      .exam-result-complete {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 56px;
        background: linear-gradient(270deg, rgba(29, 196, 130, 0.01) 0%, rgba(29, 196, 130, 0.11) 100%);
        padding-left: 16px;
        padding-right: 16px;

        .complete-label {
          display: flex;
          align-items: center;
          color: #16b97f;
          font-size: 20px;
          line-height: 20px;
        }

        .exam-score-label {
          color: #3a485e;
          font-size: 14px;
          line-height: 14px;
        }

        .exam-score-num {
          color: #16b97f;
          font-size: 26px;
          line-height: 26px;
          margin-left: 12px;
        }
      }

      .exam-result-no-complete {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 56px;
        background: linear-gradient(270deg, rgba(250, 74, 57, 0.01) 0%, rgba(250, 74, 57, 0.11) 100%);
        padding-left: 16px;
        padding-right: 16px;

        .no-complete-label {
          display: flex;
          align-items: center;
          color: #fa4a39;
          font-size: 20px;
          line-height: 20px;
        }

        .exam-score-label {
          color: #3a485e;
          font-size: 14px;
          line-height: 14px;
        }

        .exam-score-num {
          color: #fa4a39;
          font-size: 26px;
          line-height: 26px;
          margin-left: 12px;
        }
      }

      .answer-sheet {
        background: #ffffff;
        box-shadow: 0px 1px 0px #e1e5f4;
        padding: 13px 13px 13px 16px;
        display: flex;
        justify-content: space-between;

        .answer-sheet-text {
          color: #3a485e;
          font-size: 14px;
          line-height: 14px;
        }

        .sample-text {
          display: flex;
          align-items: center;

          .answer-tag {
            width: 10px;
            height: 10px;
            border-radius: 2px;
            margin-left: 12px;
            margin-right: 2px;
          }

          .tag-text {
            color: #3a485e;
            font-size: 12px;
            line-height: 14px;
          }
        }
      }

      .answer-detail {
        height: calc(100% - 149px);
        padding: 20px 0 20px 16px;

        .answer-card {
          display: inline-block;
          width: 41px;
          height: 22px;
          border-radius: 2px;
          background: #1dc482;
          text-align: center;
          margin-right: 16px;
          margin-bottom: 16px;
        }
      }
    }
  }
}
.zwx-checkbox-group /deep/ .el-checkbox__input{
  white-space: break-spaces !important;
}
.zwx-checkbox-group /deep/ .el-checkbox__label{
  display: math !important;
}
.el-checkbox, .el-checkbox__input{
  white-space: break-spaces !important;
}
</style>
