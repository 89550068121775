import { PageLayout, UnderDevelopment } from 'vue-el-web'
import { NewsPublish, NewsPublishEdit, ImportRecordList,NewsPublishList } from 'vue-el-web'
import TopicManageEdit from '../views/topicManage/TopicManageEdit.vue'
// 默认路由地址
const plugRoutes = [
  {
    path: '/',
    redirect: '/login',
  },
]

// 一个菜单对应一个父级路由
// 一个父级路由下面不允许存在多级children
// noRequireAuth：表示不需要进行登录验证，也不需要进行菜单权限验证
// menuRoute：表示需要进行登录验证，也需要进行菜单权限验证，但是路由切换不会再次校验登录信息
// noMenuRoute：表示需要进行登录验证，但不需要进行菜单权限验证，路由切换会再次校验登录信息
// 无：表示不需要进行登录验证，也不需要进行菜单权限验证，也表示该页面不能直接打开，需要通过路由跳转
const views = plugRoutes.concat([
  {
    path: '/login',
    name: 'MsLogin',
    component: () => import('../views/login/Index.vue'),
    meta: { noRequireAuth: true },
  },
  /**
   * 单点登录
   */
  {
    path: '/singlePoint',
    name: 'SinglePoint',
    component: () => import('../views/login/SinglePoint.vue'),
    meta: { noRequireAuth: true },
  },
  {
    path: '/emptyPage',
    name: 'UnderDevelopment',
    component: UnderDevelopment,
    meta: { noRequireAuth: true },
  },
  //题目管理
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/onlineTraining/topicManage',
        name: 'TopicManage',
        component: () => import('../views/topicManage/TopicManage.vue'),
        meta: { menuRoute: true, title: '题目管理' },
      },
      {
        path: '/topicManageEdit',
        name: 'TopicManageEdit',
        component: TopicManageEdit,
        meta: { title: '编辑' },
      },
    ],
  },
  //考试管理
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/onlineTraining/ExaminationManagement',
        name: 'ExaminationManagement',
        component: () => import('../views/examinationManage/ExaminationManagement.vue'),
        meta: { menuRoute: true, title: '考试管理', ifDownLoad: false },
      },
      {
        path: '/onlineTraining/ExaminationManagementEdit',
        name: 'ExaminationManagementEdit',
        component: () => import('../views/examinationManage/ExaminationManagementEdit.vue'),
        meta: { title: '编辑', showFiled: true },
      },
    ],
  },
  // 教材管理
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/onlineTraining/materialManage',
        name: 'MaterialManage',
        component: () => import('../views/materialManage/MaterialManage.vue'),
        meta: { menuRoute: true },
      },
      {
        path: '/materialManageEdit',
        name: 'MaterialManageEdit',
        component: () => import('../views/materialManage/MaterialManageEdit.vue'),
        meta: { title: '编辑' },
      },
    ],
  },
  // 专家管理
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/onlineTraining/ExpertManagement',
        name: 'ExpertManagement',
        component: () => import('../views/expertManage/ExpertManagement.vue'),
        meta: { menuRoute: true },
      },
      {
        path: '/expertManagementEdit',
        name: 'ExpertManagementEdit',
        component: () => import('../views/expertManage/ExpertManagementEdit.vue'),
        meta: { title: '编辑' },
      },
    ],
  },
  // 课程配置
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/onlineTraining/courseManage',
        name: 'CourseManage',
        component: () => import('../views/courseManage/CourseManage.vue'),
        meta: { menuRoute: true },
      },
      {
        path: '/courseManageEdit',
        name: 'CourseManageEdit',
        component: () => import('../views/courseManage/CourseManageEdit.vue'),
        meta: { title: '编辑', showFiled: true },
      },
    ],
  },
  // 平台商品管理
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/product',
        name: 'product',
        component: () => import('../views/product/index.vue'),
        meta: { menuRoute: true },
      },
      {
        path: '/product/create',
        name: 'product.create',
        component: () => import('../views/product/edit/index.vue'),
        meta: { title: '创建' },
      },
      {
        path: '/product/edit',
        name: 'product.edit',
        component: () => import('../views/product/edit/index.vue'),
        meta: { title: '编辑' },
      },
    ],
  },
  // 运营情况查询
  {
    path: '/operationSituation',
    component: PageLayout,
    children: [
      {
        path: 'courseRecord',
        name: 'operationSituation.courseRecord',
        component: () => import('../views/operationSituation/courseRecord/index.vue'),
        meta: { menuRoute: true },
      },
      {
        path: 'examinationRecord',
        name: 'operationSituation.examinationRecord',
        component: () => import('../views/operationSituation/examinationRecord/index.vue'),
        meta: { menuRoute: true },
      },
      {
        path: 'certificateIssuingRecord',
        name: 'operationSituation.certificateIssuingRecord',
        component: () => import('../views/operationSituation/certificateIssuingRecord/index.vue'),
        meta: { menuRoute: true },
      },
      {
        path: 'financialRecord',
        name: 'operationSituation.financialRecord',
        component: () => import('../views/operationSituation/financialRecord/index.vue'),
        meta: { menuRoute: true },
      },
    ],
  },
  //平台信息发布
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/om/newsPublish',
        name: 'NewsPublish',
        component: NewsPublish,
        meta: { menuRoute: true },
      },
      {
        path: '/newsPublishEdit',
        name: 'NewsPublishEdit',
        component: NewsPublishEdit,
        meta: { title: '编辑' },
      },
    ],
  },
  // 商户信息管理
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/merchantInfo',
        name: 'merchantInfo',
        component: () => import('../views/merchantInfo/index.vue'),
        meta: { menuRoute: true },
      },
    ],
  },
  // 开票配置管理
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/invoiceManagement',
        name: 'invoiceManagement',
        component: () => import('../views/invoiceManagement/index.vue'),
        meta: { menuRoute: true },
      },
    ],
  },
  // 培训机构首页
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/trainingInstitutionHome',
        name: 'trainingInstitutionHome',
        component: () => import('../views/trainingInstitutionHome/index.vue'),
        meta: { menuRoute: true },
      },
    ],
  },
  // 培训机构管理
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/trainingInstitutionManagement',
        name: 'trainingInstitutionManagement',
        component: () => import('../views/trainingInstitutionManagement/index.vue'),
        meta: { menuRoute: true },
      },
      {
        path: '/trainingInstitutionManagement/create',
        name: 'trainingInstitutionManagement.create',
        component: () => import('../views/trainingInstitutionManagement/edit/index.vue'),
        meta: { title: '创建' },
      },
      {
        path: '/trainingInstitutionManagement/edit',
        name: 'trainingInstitutionManagement.edit',
        component: () => import('../views/trainingInstitutionManagement/edit/index.vue'),
        meta: { title: '编辑' },
      },
      {
        path: '/trainingInstitutionManagement/detail',
        name: 'trainingInstitutionManagement.detail',
        component: () => import('../views/trainingInstitutionManagement/detail/index.vue'),
        meta: { title: '详情' },
      },
    ],
  },
  // 培训班管理课程配置
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/onlineTraining/WxCourseManage',
        name: 'WxCourseManage',
        component: () => import('../views/wxcourseManage/WxCourseManage.vue'),
        meta: { menuRoute: true },
      },
      {
        path: '/WxCourseManageEdit',
        name: 'WxCourseManageEdit',
        component: () => import('../views/wxcourseManage/WxCourseManageEdit.vue'),
        meta: { title: '编辑', showFiled: true },
      },
      // 培训班创建（班级管理）
      {
        path: '/trainingManagement',
        name: 'TrainingManagement',
        component: () => import('../views/trainingManagement/index.vue'),
        meta: { menuRoute: true, title: '班级管理' },
      },
      {
        path: '/trainingManagementEdit',
        name: 'TrainingManagementEdit',
        component: () => import('../views/trainingManagement/edit.vue'),
        meta: { title: '编辑' },
      },
      {
        path: '/trainingManagementDetail',
        name: 'TrainingManagementDetail',
        component: () => import('../views/trainingManagement/detail.vue'),
        meta: { title: '详情' },
      },
      // 收支明细
      {
        path: '/incomeExpenditureDetails',
        name: 'IncomeExpenditureDetails',
        component: () => import('../views/incomeExpenditureDetails/index.vue'),
        meta: { menuRoute: true },
      },
      // 学员进度
      {
        path: '/studentHours',
        name: 'StudentHours',
        component: () => import('../views/studentHours/index.vue'),
        meta: { menuRoute: true, title: '学员进度' },
      },
      {
        path: '/importRecordList',
        name: 'importRecordList',
        component: ImportRecordList,
        meta: { menuRoute: true, title: '导入记录', searchType: 1 },
      },
      /**
       * 医学会端查看所有的培训记录
       */
      {
        path: '/studentHoursByYiXuHui',
        name: 'StudentHoursByYiXuHui',
        component: () => import('../views/studentHours/indexByYiXuHui.vue'),
        meta: { menuRoute: true },
      },
      // 培训班明细
      {
        path: '/medicineTrainingManagement',
        name: 'MedicineTrainingManagement',
        component: () => import('../views/medicineTrainingManagement/index.vue'),
        meta: { menuRoute: true },
      },
      {
        path: '/medicineTrainingManagementDetail',
        name: 'MedicineTrainingManagementDetail',
        component: () => import('../views/medicineTrainingManagement/detail.vue'),
        meta: { title: '详情' },
      },
      // 协会端区域分析
      {
        path: '/dataAnalysisByYxh',
        name: 'DataAnalysisByYxh',
        component: () => import('../views/dataAnalysis/index.vue'),
        meta: { menuRoute: true },
      },
      // 培训班区域分析
      {
        path: '/dataAnalysis',
        name: 'DataAnalysis',
        component: () => import('../views/dataAnalysispx/index.vue'),
        meta: { menuRoute: true },
      },
      /**
       * 学员考试明细页
       */
      {
        path: '/studentExamDetails',
        name: 'StudentExamDetails',
        component: () => import('../views/student-exam-details/studentExamList.vue'),
        meta: { menuRoute: true },
      },
      {
        path: '/examResultDetails',
        name: 'ExamResultDetails',
        component: () => import('../views/student-exam-details/examResultDetails.vue'),
        meta: { menuRoute: true, title: '答卷详情' },
      },
      /**
       * 开票管理
       */
      {
        path: '/invoiceApplicationList',
        name: 'InvoiceApplicationList',
        component: () => import('../views/invoice/invoiceApplicationList.vue'),
        meta: { menuRoute: true },
      },
      /**
       * 数据上报记录查询
       */
      {
        path: '/uploadRecordList',
        name: 'uploadRecordList',
        component: () => import('../views/data-upload/uploadRecordList.vue'),
        meta: { menuRoute: true },
      },
    ],
  },
])

export default views
