/**
 * test模块
 */
import { PageLayout, DefaultLayout } from 'vue-el-web'
import { CourseManage, CourseManageEdit } from 'vue-training-base'
import { MenuManage, MenuEdit, MenuDetail, MenuBtn, MenuBtnEdit } from 'vue-el-web'
// 一个菜单对应一个父级路由
// 一个父级路由下面不允许存在多级children
// noRequireAuth：表示不需要进行登录验证，也不需要进行菜单权限验证
// menuRoute：表示需要进行登录验证，也需要进行菜单权限验证，但是路由切换不会再次校验登录信息
// noMenuRoute：表示需要进行登录验证，但不需要进行菜单权限验证，路由切换会再次校验登录信息
// 无：表示不需要进行登录验证，也不需要进行菜单权限验证，也表示该页面不能直接打开，需要通过路由跳转
const viewsTest = [
  /**
   * 模块一
   */
  {
    path: '/default',
    name: 'DefaultLayout',
    component: DefaultLayout,
    // component: HomeIndex,
    meta: {
      noMenuRoute: true,
      header: '64',
      rightNavbar: true,
      logoText: true,
      passwordModifyVisible: false,
      selfImg: 'header-logo.png',
    },
  },
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/onlineTraining/courseManage',
        name: 'CourseManage',
        component: CourseManage,
        meta: { noRequireAuth: true },
      },
      {
        path: '/courseManageEdit',
        name: 'CourseManageEdit',
        component: CourseManageEdit,
        meta: { title: '编辑' },
      },
    ],
  },
  /**
   * 模块二
   */

  // 企业帮扶
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/unitHelp/list',
        name: 'UnitHelpList',
        component: () => import('../views/business/unitHelp/list.vue'),
        meta: { menuRoute: true, title: '企业帮扶' },
      },
      {
        path: '/unitHelp/edit',
        name: 'UnitHelpEdit',
        component: () => import('../views/business/unitHelp/edit.vue'),
        meta: { menuRoute: true, title: '企业帮扶' },
      },
      {
        path: '/unitHelp/select',
        name: 'UnitHelpSelect',
        component: () => import('../views/business/unitHelp/select.vue'),
        meta: { menuRoute: true, title: '企业遴选' },
      },
      {
        path: '/unitHelp/detail',
        name: 'UnitHelpDetail',
        component: () => import('../views/business/unitHelp/detail.vue'),
        meta: { menuRoute: true, title: '详情' },
      },
    ],
  },
  /**
   * 体检档案库
   */
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/health_surveillance/management/StaffTdPeRecordManagement',
        name: 'StaffTdPeRecordManagement',
        component: () => import('@/views/business/health_surveillance/management/staff_record/StaffTdPeRecordList.vue'),
        meta: { menuRoute: true, title: '体检档案库' },
      },
      {
        path: '/health_surveillance/management/StaffPeRecordDetailPage',
        name: 'StaffPeRecordDetailPage',
        component: () => import('@/views/business/health_surveillance/management/StaffPeRecordDetailPage.vue'),
        meta: { menuRoute: true, title: '档案详情' },
      },
      {
        path: '/health_surveillance/management/peRecordDetail',
        name: 'PeRecordDetail',
        component: () => import('@/views/business/health_surveillance/management/DetailPage.vue'),
        meta: { menuRoute: true, title: '体检详情' },
      },
    ],
  },
  /**
   * 体检记录库
   */
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/health_surveillance/management/PeRecordManagement',
        name: 'PeRecordManagement',
        component: () => import('@/views/business/health_surveillance/management/PeRecordList.vue'),
        meta: { menuRoute: true, title: '体检记录库' },
      },
      {
        path: '/health_surveillance/management/peRecordDetail',
        name: 'PeRecordDetail',
        component: () => import('@/views/business/health_surveillance/management/DetailPage.vue'),
        meta: { menuRoute: true, title: '体检详情' },
      },
    ],
  },
  /**
   * 导航功能
   */
  {
    path: '/',
    component: PageLayout,
    children: [
      //功能导航查询列表
      {
        path: '/repository/navigation/navigationList',
        name: 'NavigationListIndex',
        component: () => import('@/views/business/repository/navigation/navigationList.vue'),
        meta: { menuRoute: true, title: '功能导航' },
      },
      //功能导航编辑
      {
        path: '/repository/navigation/navigationEdit',
        name: 'NavigationEditIndex',
        component: () => import('@/views/business/repository/navigation/navigationEdit.vue'),
        meta: { menuRoute: true, title: '编辑' },
      },
      //功能导航编辑
      {
        path: '/repository/navigation/navigationDetail',
        name: 'NavigationDetailIndex',
        component: () => import('@/views/business/repository/navigation/navigationDetail.vue'),
        meta: { menuRoute: true, title: '详情' },
      },
      //导航角色分配
      {
        path: '/repository/navigation/navigationRole',
        name: 'NavigationRoleIndex',
        component: () => import('@/views/business/repository/navigation/navigationRole.vue'),
        meta: { menuRoute: true, title: '详情' },
      },
    ],
  },
]

export default viewsTest
