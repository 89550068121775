import { DefaultLayout, PageLayout, ErrorPage } from 'vue-el-web'
import { ParamManage, ParamEdit, AreaManage } from 'vue-el-web'
import { MenuManage, MenuEdit, MenuDetail, MenuBtn, MenuBtnEdit } from 'vue-el-web'
import { RoleManage, RoleEdit, RoleMenu } from 'vue-el-web'
import { CodeManage, SimpleCode, SimpleCodeEdit } from 'vue-el-web'
import { ApiLog, NewsPublish, NewsPublishEdit } from 'vue-el-web'
import { UnitManage, UnitEdit, UnitDetail } from 'vue-el-web'
import { UserManage, UserEdit, UserDetail, UserRole } from 'vue-el-web'
import {QuartzManage, QuartzEdit} from 'vue-el-web'

// 一个菜单对应一个父级路由
// 一个父级路由下面不允许存在多级children
// noRequireAuth：表示不需要进行登录验证，也不需要进行菜单权限验证
// menuRoute：表示需要进行登录验证，也需要进行菜单权限验证，但是路由切换不会再次校验登录信息
// noMenuRoute：表示需要进行登录验证，但不需要进行菜单权限验证，路由切换会再次校验登录信息
// 无：表示不需要进行登录验证，也不需要进行菜单权限验证，也表示该页面不能直接打开，需要通过路由跳转
const packages = [
  // {
  //   path: '/default',
  //   name: 'DefaultLayout',
  //   component: DefaultLayout,
  //   meta: { noMenuRoute: true, header: '64', logoText: false },
  // },
 
  {
    path: '/login',
    name: 'MsLogin',
    component: ()=>import('../views/login/login.vue'),
    meta: { noRequireAuth: true },
  },
  {
    path: '/error',
    name: 'ErrorPage',
    component: ErrorPage,
    meta: { noRequireAuth: true },
  },
  {
    path: '/selectRegister',
    name: 'selectRegister',
    component: () => import('../views/register/selectRegister.vue'),
    meta: { noRequireAuth: true },
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/register/Index.vue'),
    meta: { noRequireAuth: true },
  },
  {
    path: '/reigsterUnit',
    name: 'ReigsterUnit',
    component: () => import('../views/register/reigsterUnit.vue'),
    meta: { noRequireAuth: true },
  },
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/parameter',
        name: 'ParamManage',
        component: ParamManage,
        meta: { menuRoute: true },
      },
      {
        path: '/parameterEdit',
        name: 'ParamEdit',
        component: ParamEdit,
        meta: { title: '编辑' },
      },
    ],
  },
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/area',
        name: 'AreaManage',
        component: AreaManage,
        meta: { menuRoute: true },
      },
    ],
  },
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/menu',
        name: 'MenuManage',
        component: MenuManage,
        meta: { menuRoute: true },
      },
      {
        path: '/menuEdit',
        name: 'MenuEdit',
        component: MenuEdit,
        meta: { title: '编辑' },
      },
      {
        path: '/menuDetail',
        name: 'MenuDetail',
        component: MenuDetail,
        meta: { title: '详情' },
      },
      {
        path: '/menuBtn',
        name: 'MenuBtn',
        component: MenuBtn,
        meta: { title: '功能按钮维护' },
      },
      {
        path: '/menuBtnEdit',
        name: 'MenuBtnEdit',
        component: MenuBtnEdit,
        meta: { title: '编辑' },
      },
    ],
  },
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/role',
        name: 'RoleManage',
        component: RoleManage,
        meta: { menuRoute: true },
      },
      {
        path: '/roleEdit',
        name: 'RoleEdit',
        component: RoleEdit,
        meta: { title: '编辑' },
      },
      {
        path: '/roleMenu',
        name: 'RoleMenu',
        component: RoleMenu,
        meta: { title: '菜单分配' },
      },
    ],
  },
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/code',
        name: 'CodeManage',
        component: CodeManage,
        meta: { menuRoute: true },
      },
      {
        path: '/codeSimpleCode',
        name: 'SimpleCode',
        component: SimpleCode,
        meta: { title: '码表维护' },
      },
      {
        path: '/codeSimpleCodeEdit',
        name: 'SimpleCodeEdit',
        component: SimpleCodeEdit,
        meta: { title: '编辑' },
      },
    ],
  },
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/apiLog',
        name: 'ApiLog',
        component: ApiLog,
        meta: { menuRoute: true },
      },
    ],
  },
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/unit',
        name: 'UnitManage',
        component: UnitManage,
        meta: { menuRoute: true, unitTypeCodeTypeCode: '1034' },
      },
      {
        path: '/unitEdit',
        name: 'UnitEdit',
        component: UnitEdit,
        meta: { title: '编辑' },
      },
      {
        path: '/unitDetail',
        name: 'UnitDetail',
        component: UnitDetail,
        meta: { title: '详情' },
      },
    ],
  },
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/user',
        name: 'UserManage',
        component: UserManage,
        meta: {
          menuRoute: true,
          userSexCodeTypeCode: '1001',
          userNationCodeTypeCode: '1002',
          educationCodeTypeCode: '1003',
          degreeCodeTypeCode: '1004',
          maritalStatusCodeTypeCode: '1005',
          politicalLandscapeCodeTypeCode: '1006',
          religionCodeTypeCode: '1007',
        },
      },
      {
        path: '/userEdit',
        name: 'UserEdit',
        component: UserEdit,
        meta: { title: '编辑' },
      },
      {
        path: '/userDetail',
        name: 'UserDetail',
        component: UserDetail,
        meta: { title: '详情' },
      },
      {
        path: '/userRole',
        name: 'UserRole',
        component: UserRole,
        meta: { title: '角色分配' },
      },
    ],
  },
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/quartz',
        name: 'QuartzManage',
        component: QuartzManage,
        meta: { menuRoute: true },
      },
      {
        path: '/quartzEdit',
        name: 'QuartzEdit',
        component: QuartzEdit,
        meta: { title: '编辑' },
      },
    ],
  },
  //平台信息发布
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/om/newsPublish',
        name: 'NewsPublish',
        component: NewsPublish,
        meta: { menuRoute: true },
      },
      {
        path: '/newsPublishEdit',
        name: 'NewsPublishEdit',
        component: NewsPublishEdit,
        meta: { menuRoute: true, title: '编辑' },
      },
    ],
  },
  
]

export default packages
