<template>
  <div class="footer flex-column-center">
    <div class="footer-body flex-column-center">
      <div style="margin-bottom:33px">
        ©2021国家卫生健康委职业安全卫生研究中心all rights reserved
      </div>
      <div class="flex-row-center">
        <div>技术支持：{{unitName}} <span style="cursor: pointer;margin-left:5px" @click="domainNumberClick">{{ domainNumber }}</span></div>
        <div class="line"></div>
        <img src="../../assets/images/portal/img-national-emblem.png" alt="" style="width:14px;height:14px;margin-right: 12px" />
        <div style="cursor: pointer;" @click="psnClick">{{ psn }}</div>
        <div class="line"></div>
        <div>用户访问：{{userVisits}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PortalFooter',
  data() {
    return {
      api: this.$store.state.api,
      file: this.$store.state.file,
      unitName: this.$store.state.unitName,
      domainNumber: this.$store.state.domainNumber,
      psn: this.$store.state.psn,
       psnNumber: this.$store.state.psnNumber,
      userVisits:1514584545
    }
  },
  created() { },
  methods: {
    domainNumberClick() {
      window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank')
    },
    psnClick() {
      window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=' + this.psnNumber, '_blank')
    },
  },
}
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  height: 130px;
  background: #434f66;
  .footer-body {
    margin: 0 auto;
    width: 1200px;
    opacity: 0.7;
    font-size: 12px;
    color: #ffffff;
    .line {
      width: 1px;
      height: 12px;
      background: #caced7;
      margin: 0 12px;
    }
  }
}
</style>
