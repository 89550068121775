/**
 * 验证值是否为空
 * */
let verifyIsBlank = value => {
  if (value === undefined || value === null || value.length === 0 || typeof value === 'undefined') {
    return true
  }
  return false
}
/**
 * 验证值是否不为空
 * */
let verifyIsNotBlank = value => {
  return !verifyIsBlank(value)
}
/**
 * 一维数组变二维数组
 * */
let multidimensionalArrayDeal = (arr, arrLen) => {
  let newArr = []
  let tempArr = []
  for (let i = 1; i <= arr.length; i++) {
    tempArr.push(arr[i - 1])
    if (i % arrLen === 0) {
      newArr.push(tempArr)
      tempArr = []
    } else if (i === arr.length) {
      newArr.push(tempArr)
    }
  }
  return newArr
}

/**
 * 二维数组转换一维数组
 * */
let arrayDeal = arr => {
  let newArr = []
  for (let i = 0; i < arr.length; i++) {
    for (let j = 0; j < arr[i].length; j++) {
      newArr.push(arr[i][j])
    }
  }
  return newArr
}

/**
 * 字符串数字去除小数点末尾0
 * */
let getNumberByStr = str => {
  var numReg = /^[0-9]*$/
  var numRe = new RegExp(numReg)
  if (verifyIsNotBlank(str) && !numRe.test(str)) {
    return Number(str)
  } else {
    return str
  }
}

/**
 * 获取当前时间
 * */
let getCurrentTime = () => {
  var date = new Date() //当前时间
  var year = date.getFullYear() //返回指定日期的年份
  var month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1) //月
  var day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate() //日
  var hour = date.getHours() > 9 ? date.getHours() : '0' + date.getHours() //时
  var minute = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes() //分
  var second = date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds() //秒
  //当前时间
  var curTime = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second
  return curTime
}
/**
 * 根据文件后缀获取文件图标
 * @param file
 * @returns {string}
 */
let dealfilePrefix = file => {
  let prefix = file.substring(file.lastIndexOf('.'))
  let fileIconShow = ''
  if (prefix === '.pdf' || prefix === '.PDF') { 
    fileIconShow = 'colorful chis-icon-colorful-pdf'
  } else if (prefix === '.doc' || prefix === '.docx' || prefix === '.DOC' || prefix === '.DOCX') {
    fileIconShow = 'colorful chis-icon-colorful-word'
  } else if (prefix === '.png' || prefix === '.jpeg' || prefix === '.jpg' || prefix === '.JPG' || prefix === '.JPEG' || prefix === '.PNG') {
    fileIconShow = 'colorful chis-icon-colorful-jpg'
  }else if (prefix === '.mp4' ) {
    fileIconShow = 'colorful chis-icon-colorful-mp4'
  }else if (prefix === '.xls' || prefix === '.xlsx' || prefix === '.XLS' || prefix === '.XLSX' ) {
    fileIconShow = 'colorful chis-icon-colorful-excel'
  }else if (prefix === '.ppt' || prefix === '.pptx' || prefix === '.PPT' || prefix === '.PPTX' ) {
    fileIconShow = 'colorful chis-icon-colorful-ppt'
  }
  return fileIconShow
}

/**
 * 获取字符串宽度
 * @param text
 * @param fontSize
 * @returns {number}
 */
let getTextWidth = (text, fontSize) => {
  const _span = document.createElement('span')
  _span.innerText = text
  _span.style.fontSize = fontSize + 'px'
  _span.style.position = 'absolute'
  document.body.appendChild(_span)
  let width = _span.offsetWidth
  document.body.removeChild(_span)
  return width
}

/**
 * @description 格式化金额(自定义)
 * @param number：要格式化的数字
 * @param decimals：保留几位小数 默认0位
 * @param decPoint：小数点符号 默认.
 * @param thousandsSep：千分位符号 默认为,
 */
let formatMoneyCustom = (number, decimals = 0, decPoint = '.', thousandsSep = ',') => {
  number = (number + '').replace(/[^0-9+-Ee.]/g, '')
  let n = !isFinite(+number) ? 0 : +number
  let prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
  let sep = typeof thousandsSep === 'undefined' ? ',' : thousandsSep
  let dec = typeof decPoint === 'undefined' ? '.' : decPoint
  let s = ''
  let toFixedFix = function(n, prec) {
    let k = Math.pow(10, prec)
    return '' + Math.ceil(n * k) / k
  }
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
  let re = /(-?\d+)(\d{3})/
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, '$1' + sep + '$2')
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }
  return s.join(dec)
}

/**
 * @description 格式化金额
 * @param number：要格式化的数字
 */
let formatMoney = number => {
  if (verifyIsNotBlank(number)) {
    number = number + ''
    let money = ''
    if (number.indexOf('.') > 0) {
      let arr = number.split('.') || []
      money = formatMoneyCustom(number, arr[1].length)
    } else {
      money = formatMoneyCustom(number, 0)
    }
    return money
  }
  return number
}

export default { verifyIsBlank, verifyIsNotBlank, multidimensionalArrayDeal, arrayDeal, getNumberByStr, getCurrentTime, dealfilePrefix, getTextWidth, formatMoneyCustom, formatMoney }
