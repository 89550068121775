<template>
  <el-dropdown-item v-if="roleExpList.length > 1">
    <el-popover popper-class="ms-el-popper" placement="left" trigger="hover">
      <span slot="reference">切换角色</span>
      <div class="dropdown-list" slot="default">
        <div v-for="(item, index) in roleExpList" :class="['dropdown-list-item', { selectItem: userRole == item.roleCode }]" @click="changeItem(item)" :key="index">
          {{ item.roleName }}
        </div>
      </div>
    </el-popover>
  </el-dropdown-item>
</template>
<script>
export default {
  name: 'CustomDropdown',
  data() {
    return {
      api: this.$store.state.api,
      roleExpList: this.$store.state.roleExpList,
      userRole: '',
      roleCodeMap: { '27aa9771904d7d45': '/employer/#/default', a47694a02ed7b7fc: '/technical/#/default', '5ec06fb2c9bfd9b0': '/bussiness/#/default' },
    }
  },
  created() {
    this.userRole = this.$zwxCookie.getCookie('userRole')
    console.log
    // this.roleExpList =
    // this.getRole()
  },
  methods: {
    changeItem({ roleCode }) {
      this.$zwxCookie.addCookie('userRole', roleCode)
      // window.location.replace(this.roleCodeMap[roleCode])
      window.location.reload()
    },
    getRole() {
      this.$emit('loading', true)
      this.$system.post(
        this.api + '/zky/employer/getRoleListByUserUid-1',
        {},
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            console.log(data, 'data')
            this.roleExpList = data.roleExpList
          } else if (data.type === '99') {
            this.$router.push({ name: 'MsError', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
  },
}
</script>
<style lang="less" scoped>
.dropdown-list {
  &-item {
    padding: 0;
    height: 43px;
    text-align: center;
    line-height: 43px;
    font-size: 13px;
    font-weight: 400;
    background: 0 0 !important;
    color: #1f2d3d !important;
    cursor: pointer;
    &:hover {
      background-color: #ecf5ff !important;
      color: #66b1ff !important;
    }
    &.selectItem {
      background-color: #ecf5ff !important;
      color: #66b1ff !important;
    }
  }
  // &-item:hover {
  //   background-color: #ecf5ff !important;
  //   color: #66b1ff !important;
  // }
}
</style>
<style lang="less">
.ms-el-popper {
  padding: 0 !important;
  // min-width: 100px !important;
}
</style>
