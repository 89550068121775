var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "dragDiv", staticClass: "drag" }, [
    _c("div", { staticClass: "drag_bg" }),
    _c("div", { staticClass: "drag_text" }, [_vm._v(_vm._s(_vm.confirmWords))]),
    _vm.isShow
      ? _c("div", {
          ref: "moveDiv",
          staticClass: "handler handler_bg",
          class: { handler_ok_bg: _vm.confirmSuccess },
          staticStyle: { position: "absolute", top: "0", left: "0" },
          style: { left: _vm.confirmSuccess ? "maxWidth" : "0" },
          on: {
            mousedown: function($event) {
              return _vm.mouseDownFn($event)
            }
          }
        })
      : _c("div", {
          ref: "moveDiv",
          staticClass: "handler handler_bg",
          class: { handler_ok_bg: _vm.confirmSuccess },
          staticStyle: { position: "absolute", top: "0", left: "0" },
          style: { left: _vm.confirmSuccess ? "maxWidth" : "0" },
          on: {
            touchstart: function($event) {
              return _vm.touchstart($event)
            }
          }
        })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }