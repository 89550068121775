var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "zwx-exam-record-detail-index" }, [
    _c(
      "div",
      {
        staticStyle: {
          width: "100%",
          display: "flex",
          "justify-content": "center"
        }
      },
      [
        _c(
          "div",
          { staticClass: "zwx-exam-record-main" },
          [
            _c(
              "zwx-scrollbar-y",
              {
                ref: "searchScrollbar",
                staticStyle: {
                  height: "100%",
                  width: "calc(100% - 328px)",
                  background: "#ffffff"
                }
              },
              [
                _vm.examRecordDetailForm.scoreMode != 0
                  ? _c(
                      "div",
                      { staticClass: "zwx-exam-record-main-left" },
                      [
                        _vm.$zwxBase.verifyIsNotBlank(
                          _vm.examRecordDetailForm.radioQuestionList
                        )
                          ? _c("base-headline", {
                              staticClass: "no-top",
                              staticStyle: { "padding-right": "2px" },
                              attrs: { title: "单选题" }
                            })
                          : _vm._e(),
                        _vm.$zwxBase.verifyIsNotBlank(
                          _vm.examRecordDetailForm.radioQuestionList
                        )
                          ? _c(
                              "div",
                              { staticClass: "question-body" },
                              [
                                _vm._l(
                                  _vm.examRecordDetailForm.radioQuestionList,
                                  function(item, i) {
                                    return [
                                      _c("div", { staticClass: "question" }, [
                                        _c(
                                          "div",
                                          { staticClass: "question-head" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "question-num" },
                                              [
                                                _vm._v(
                                                  _vm._s(item.serialNumber) +
                                                    "、"
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "question-title" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.tbTrainingTopicExp
                                                        .topicContent
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm.$zwxBase.verifyIsNotBlank(
                                          item.tbTrainingTopicExp.picPath
                                        )
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "question-pic",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openFilePreview(
                                                      item.tbTrainingTopicExp
                                                        .picPath
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    width: "100%",
                                                    height: "100%"
                                                  },
                                                  attrs: {
                                                    src:
                                                      _vm.$store.state.file +
                                                      item.tbTrainingTopicExp
                                                        .picPath
                                                  }
                                                })
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.$zwxBase.verifyIsBlank(
                                          item.tbTrainingTopicExp.optionLayout
                                        ) ||
                                        item.tbTrainingTopicExp.optionLayout ===
                                          1
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "question-options-column"
                                              },
                                              [
                                                _vm._l(
                                                  item.tbTrainingTopicExp
                                                    .trainingOptionList,
                                                  function(opt) {
                                                    return [
                                                      item.optionValue ===
                                                        opt.rid &&
                                                      opt.ifAnswer === true
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "choose-right"
                                                            },
                                                            [
                                                              _c(
                                                                "el-radio",
                                                                {
                                                                  key: opt.rid,
                                                                  staticClass:
                                                                    "zwx-radio",
                                                                  attrs: {
                                                                    label:
                                                                      opt.rid,
                                                                    disabled: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.optionValue,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item,
                                                                        "optionValue",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "item.optionValue"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      opt.optionCode
                                                                    ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        opt.optionContent
                                                                      )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      item.optionValue ===
                                                        opt.rid &&
                                                      opt.ifAnswer === false
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "choose-error"
                                                            },
                                                            [
                                                              _c(
                                                                "el-radio",
                                                                {
                                                                  key: opt.rid,
                                                                  staticClass:
                                                                    "zwx-radio",
                                                                  attrs: {
                                                                    label:
                                                                      opt.rid,
                                                                    disabled: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.optionValue,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item,
                                                                        "optionValue",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "item.optionValue"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      opt.optionCode
                                                                    ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        opt.optionContent
                                                                      )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      item.optionValue !==
                                                      opt.rid
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "el-radio",
                                                                {
                                                                  key: opt.rid,
                                                                  staticClass:
                                                                    "zwx-radio",
                                                                  attrs: {
                                                                    label:
                                                                      opt.rid,
                                                                    disabled: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.optionValue,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item,
                                                                        "optionValue",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "item.optionValue"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      opt.optionCode
                                                                    ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        opt.optionContent
                                                                      )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                        item.tbTrainingTopicExp.optionLayout ===
                                        2
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "question-options-default"
                                              },
                                              [
                                                _vm._l(
                                                  item.tbTrainingTopicExp
                                                    .trainingOptionList,
                                                  function(opt) {
                                                    return [
                                                      item.optionValue ===
                                                        opt.rid &&
                                                      opt.ifAnswer === true
                                                        ? _c("div", [
                                                            _vm.$zwxBase.verifyIsNotBlank(
                                                              opt.picPath
                                                            )
                                                              ? _c("img", {
                                                                  staticStyle: {
                                                                    height:
                                                                      "90px",
                                                                    "margin-bottom":
                                                                      "20px",
                                                                    "margin-right":
                                                                      "60px",
                                                                    "padding-left":
                                                                      "28px"
                                                                  },
                                                                  attrs: {
                                                                    src:
                                                                      _vm.$store
                                                                        .state
                                                                        .file +
                                                                      opt.picPath
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.openFilePreview(
                                                                        opt.picPath
                                                                      )
                                                                    }
                                                                  }
                                                                })
                                                              : _vm._e(),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "choose-right",
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "28px"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "el-radio",
                                                                  {
                                                                    key:
                                                                      opt.rid,
                                                                    staticClass:
                                                                      "zwx-radio",
                                                                    attrs: {
                                                                      label:
                                                                        opt.rid,
                                                                      disabled:
                                                                        ""
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item.optionValue,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "optionValue",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "item.optionValue"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        opt.optionCode
                                                                      ) +
                                                                        " " +
                                                                        _vm._s(
                                                                          opt.optionContent
                                                                        )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ])
                                                        : _vm._e(),
                                                      item.optionValue ===
                                                        opt.rid &&
                                                      opt.ifAnswer === false
                                                        ? _c("div", [
                                                            _vm.$zwxBase.verifyIsNotBlank(
                                                              opt.picPath
                                                            )
                                                              ? _c("img", {
                                                                  staticStyle: {
                                                                    height:
                                                                      "90px",
                                                                    "margin-bottom":
                                                                      "20px",
                                                                    "margin-right":
                                                                      "60px",
                                                                    "padding-left":
                                                                      "28px"
                                                                  },
                                                                  attrs: {
                                                                    src:
                                                                      _vm.$store
                                                                        .state
                                                                        .file +
                                                                      opt.picPath
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.openFilePreview(
                                                                        opt.picPath
                                                                      )
                                                                    }
                                                                  }
                                                                })
                                                              : _vm._e(),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "choose-error",
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "28px"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "el-radio",
                                                                  {
                                                                    key:
                                                                      opt.rid,
                                                                    staticClass:
                                                                      "zwx-radio",
                                                                    attrs: {
                                                                      label:
                                                                        opt.rid,
                                                                      disabled:
                                                                        ""
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item.optionValue,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "optionValue",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "item.optionValue"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        opt.optionCode
                                                                      ) +
                                                                        " " +
                                                                        _vm._s(
                                                                          opt.optionContent
                                                                        )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ])
                                                        : _vm._e(),
                                                      item.optionValue !==
                                                      opt.rid
                                                        ? _c("div", [
                                                            _vm.$zwxBase.verifyIsNotBlank(
                                                              opt.picPath
                                                            )
                                                              ? _c("img", {
                                                                  staticStyle: {
                                                                    height:
                                                                      "90px",
                                                                    "margin-bottom":
                                                                      "20px",
                                                                    "margin-right":
                                                                      "60px",
                                                                    "padding-left":
                                                                      "28px"
                                                                  },
                                                                  attrs: {
                                                                    src:
                                                                      _vm.$store
                                                                        .state
                                                                        .file +
                                                                      opt.picPath
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.openFilePreview(
                                                                        opt.picPath
                                                                      )
                                                                    }
                                                                  }
                                                                })
                                                              : _vm._e(),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "28px"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "el-radio",
                                                                  {
                                                                    key:
                                                                      opt.rid,
                                                                    staticClass:
                                                                      "zwx-radio",
                                                                    attrs: {
                                                                      label:
                                                                        opt.rid,
                                                                      disabled:
                                                                        ""
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item.optionValue,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "optionValue",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "item.optionValue"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        opt.optionCode
                                                                      ) +
                                                                        " " +
                                                                        _vm._s(
                                                                          opt.optionContent
                                                                        )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ]
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "question-analysis",
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.changeAnalysis(
                                                  "radioQuestionList",
                                                  i
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(" 试题解析 "),
                                            _c("i", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.$zwxBase.verifyIsBlank(
                                                      item.ifShowAnalysis
                                                    ) ||
                                                    item.ifShowAnalysis ===
                                                      false,
                                                  expression:
                                                    "$zwxBase.verifyIsBlank(item.ifShowAnalysis) || item.ifShowAnalysis === false"
                                                }
                                              ],
                                              staticClass: "el-icon-arrow-down"
                                            }),
                                            _c("i", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    item.ifShowAnalysis ===
                                                    true,
                                                  expression:
                                                    "item.ifShowAnalysis === true"
                                                }
                                              ],
                                              staticClass: "el-icon-arrow-up"
                                            })
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  item.ifShowAnalysis === true,
                                                expression:
                                                  "item.ifShowAnalysis === true"
                                              }
                                            ],
                                            staticClass:
                                              "question-analysis-text",
                                            staticStyle: { color: "#1DC482" }
                                          },
                                          [
                                            _vm._v(
                                              "正确选项：" +
                                                _vm._s(
                                                  _vm.getOptionAnswer(
                                                    item.tbTrainingTopicExp
                                                      .trainingOptionList
                                                  )
                                                )
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  item.ifShowAnalysis === true,
                                                expression:
                                                  "item.ifShowAnalysis === true"
                                              }
                                            ],
                                            staticClass:
                                              "question-analysis-text"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$zwxBase.verifyIsNotBlank(
                                                    item
                                                  )
                                                    ? item.tbTrainingTopicExp
                                                        .topicAnalysis
                                                    : ""
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]),
                                      i <
                                      _vm.examRecordDetailForm.radioQuestionList
                                        .length -
                                        1
                                        ? _c("div", {
                                            staticClass: "divider-line"
                                          })
                                        : _vm._e()
                                    ]
                                  }
                                )
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm.$zwxBase.verifyIsNotBlank(
                          _vm.examRecordDetailForm.checkQuestionList
                        )
                          ? _c("base-headline", {
                              staticStyle: { "padding-right": "2px" },
                              attrs: { title: "多选题" }
                            })
                          : _vm._e(),
                        _vm.$zwxBase.verifyIsNotBlank(
                          _vm.examRecordDetailForm.checkQuestionList
                        )
                          ? _c(
                              "div",
                              { staticClass: "question-body" },
                              [
                                _vm._l(
                                  _vm.examRecordDetailForm.checkQuestionList,
                                  function(item, i) {
                                    return [
                                      _c("div", { staticClass: "question" }, [
                                        _c(
                                          "div",
                                          { staticClass: "question-head" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "question-num" },
                                              [
                                                _vm._v(
                                                  _vm._s(item.serialNumber) +
                                                    "、"
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "question-title" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.tbTrainingTopicExp
                                                        .topicContent
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm.$zwxBase.verifyIsNotBlank(
                                          item.tbTrainingTopicExp.picPath
                                        )
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "question-pic",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openFilePreview(
                                                      item.tbTrainingTopicExp
                                                        .picPath
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    width: "100%",
                                                    height: "100%"
                                                  },
                                                  attrs: {
                                                    src:
                                                      _vm.$store.state.file +
                                                      item.tbTrainingTopicExp
                                                        .picPath
                                                  }
                                                })
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.$zwxBase.verifyIsBlank(
                                          item.tbTrainingTopicExp.optionLayout
                                        ) ||
                                        item.tbTrainingTopicExp.optionLayout ===
                                          1
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "question-multi-options-column"
                                              },
                                              [
                                                _c(
                                                  "el-checkbox-group",
                                                  {
                                                    staticClass:
                                                      "zwx-checkbox-group",
                                                    attrs: { disabled: "" },
                                                    model: {
                                                      value: item.optionValue,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "optionValue",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.optionValue"
                                                    }
                                                  },
                                                  [
                                                    _vm._l(
                                                      item.tbTrainingTopicExp
                                                        .trainingOptionList,
                                                      function(opt) {
                                                        return [
                                                          _vm.$zwxBase.verifyIsNotBlank(
                                                            item.optionValue
                                                          ) &&
                                                          item.optionValue.indexOf(
                                                            opt.rid
                                                          ) != -1 &&
                                                          opt.ifAnswer === true
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "multi-option choose-right"
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-checkbox",
                                                                    {
                                                                      key:
                                                                        opt.rid,
                                                                      attrs: {
                                                                        label:
                                                                          opt.rid
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          opt.optionCode
                                                                        ) +
                                                                          " " +
                                                                          _vm._s(
                                                                            opt.optionContent
                                                                          )
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm.$zwxBase.verifyIsNotBlank(
                                                            item.optionValue
                                                          ) &&
                                                          item.optionValue.indexOf(
                                                            opt.rid
                                                          ) != -1 &&
                                                          opt.ifAnswer === false
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "multi-option choose-error"
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-checkbox",
                                                                    {
                                                                      key:
                                                                        opt.rid,
                                                                      attrs: {
                                                                        label:
                                                                          opt.rid
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          opt.optionCode
                                                                        ) +
                                                                          " " +
                                                                          _vm._s(
                                                                            opt.optionContent
                                                                          )
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm.$zwxBase.verifyIsBlank(
                                                            item.optionValue
                                                          ) ||
                                                          item.optionValue.indexOf(
                                                            opt.rid
                                                          ) == -1
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "multi-option"
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-checkbox",
                                                                    {
                                                                      key:
                                                                        opt.rid,
                                                                      attrs: {
                                                                        label:
                                                                          opt.rid
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          opt.optionCode
                                                                        ) +
                                                                          " " +
                                                                          _vm._s(
                                                                            opt.optionContent
                                                                          )
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      }
                                                    )
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        item.tbTrainingTopicExp.optionLayout ===
                                        2
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "question-multi-options-default"
                                              },
                                              [
                                                _c(
                                                  "el-checkbox-group",
                                                  {
                                                    staticClass:
                                                      "zwx-checkbox-group",
                                                    attrs: { disabled: "" },
                                                    model: {
                                                      value: item.optionValue,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "optionValue",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.optionValue"
                                                    }
                                                  },
                                                  _vm._l(
                                                    item.tbTrainingTopicExp
                                                      .trainingOptionList,
                                                    function(opt) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "cross-layout"
                                                        },
                                                        [
                                                          opt.picPath
                                                            ? _c("img", {
                                                                staticStyle: {
                                                                  height:
                                                                    "90px",
                                                                  "margin-bottom":
                                                                    "20px"
                                                                },
                                                                attrs: {
                                                                  src:
                                                                    _vm.$store
                                                                      .state
                                                                      .file +
                                                                    opt.picPath
                                                                }
                                                              })
                                                            : _vm._e(),
                                                          _vm.$zwxBase.verifyIsNotBlank(
                                                            item.optionValue
                                                          ) &&
                                                          _vm.$zwxBase.verifyIsNotBlank(
                                                            item.optionValue.filter(
                                                              e => e === opt.rid
                                                            )
                                                          ) &&
                                                          opt.ifAnswer === true
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "multi-option choose-right"
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-checkbox",
                                                                    {
                                                                      key:
                                                                        opt.rid,
                                                                      attrs: {
                                                                        label:
                                                                          opt.rid
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          opt.optionCode
                                                                        ) +
                                                                          " " +
                                                                          _vm._s(
                                                                            opt.optionContent
                                                                          )
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm.$zwxBase.verifyIsNotBlank(
                                                            item.optionValue
                                                          ) &&
                                                          _vm.$zwxBase.verifyIsNotBlank(
                                                            item.optionValue.filter(
                                                              e => e === opt.rid
                                                            )
                                                          ) &&
                                                          opt.ifAnswer === false
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "multi-option choose-error"
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-checkbox",
                                                                    {
                                                                      key:
                                                                        opt.rid,
                                                                      attrs: {
                                                                        label:
                                                                          opt.rid
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          opt.optionCode
                                                                        ) +
                                                                          " " +
                                                                          _vm._s(
                                                                            opt.optionContent
                                                                          )
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm.$zwxBase.verifyIsBlank(
                                                            item.optionValue
                                                          ) ||
                                                          _vm.$zwxBase.verifyIsBlank(
                                                            item.optionValue.filter(
                                                              e => e === opt.rid
                                                            )
                                                          )
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "multi-option"
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-checkbox",
                                                                    {
                                                                      key:
                                                                        opt.rid,
                                                                      attrs: {
                                                                        label:
                                                                          opt.rid
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          opt.optionCode
                                                                        ) +
                                                                          " " +
                                                                          _vm._s(
                                                                            opt.optionContent
                                                                          )
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "question-analysis",
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.changeAnalysis(
                                                  "checkQuestionList",
                                                  i
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(" 试题解析 "),
                                            _c("i", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.$zwxBase.verifyIsBlank(
                                                      item.ifShowAnalysis
                                                    ) ||
                                                    item.ifShowAnalysis ===
                                                      false,
                                                  expression:
                                                    "$zwxBase.verifyIsBlank(item.ifShowAnalysis) || item.ifShowAnalysis === false"
                                                }
                                              ],
                                              staticClass: "el-icon-arrow-down"
                                            }),
                                            _c("i", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    item.ifShowAnalysis ===
                                                    true,
                                                  expression:
                                                    "item.ifShowAnalysis === true"
                                                }
                                              ],
                                              staticClass: "el-icon-arrow-up"
                                            })
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  item.ifShowAnalysis === true,
                                                expression:
                                                  "item.ifShowAnalysis === true"
                                              }
                                            ],
                                            staticClass:
                                              "question-analysis-text",
                                            staticStyle: { color: "#1DC482" }
                                          },
                                          [
                                            _vm._v(
                                              "正确选项：" +
                                                _vm._s(
                                                  _vm.getOptionAnswer(
                                                    item.tbTrainingTopicExp
                                                      .trainingOptionList
                                                  )
                                                )
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  item.ifShowAnalysis === true,
                                                expression:
                                                  "item.ifShowAnalysis === true"
                                              }
                                            ],
                                            staticClass:
                                              "question-analysis-text"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$zwxBase.verifyIsNotBlank(
                                                    item
                                                  )
                                                    ? item.tbTrainingTopicExp
                                                        .topicAnalysis
                                                    : ""
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]),
                                      i <
                                      _vm.examRecordDetailForm.checkQuestionList
                                        .length -
                                        1
                                        ? _c("div", {
                                            staticClass: "divider-line"
                                          })
                                        : _vm._e()
                                    ]
                                  }
                                )
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm.$zwxBase.verifyIsNotBlank(
                          _vm.examRecordDetailForm.judgeQuestionList
                        )
                          ? _c("base-headline", {
                              staticStyle: { "padding-right": "2px" },
                              attrs: { title: "判断题" }
                            })
                          : _vm._e(),
                        _vm.$zwxBase.verifyIsNotBlank(
                          _vm.examRecordDetailForm.judgeQuestionList
                        )
                          ? _c(
                              "div",
                              { staticClass: "question-body" },
                              [
                                _vm._l(
                                  _vm.examRecordDetailForm.judgeQuestionList,
                                  function(item, i) {
                                    return [
                                      _c("div", { staticClass: "question" }, [
                                        _c(
                                          "div",
                                          { staticClass: "question-head" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "question-num" },
                                              [
                                                _vm._v(
                                                  _vm._s(item.serialNumber) +
                                                    "、"
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "question-title" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.tbTrainingTopicExp
                                                        .topicContent
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm.$zwxBase.verifyIsNotBlank(
                                          item.tbTrainingTopicExp.picPath
                                        )
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "question-pic",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openFilePreview(
                                                      item.tbTrainingTopicExp
                                                        .picPath
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    width: "100%",
                                                    height: "100%"
                                                  },
                                                  attrs: {
                                                    src:
                                                      _vm.$store.state.file +
                                                      item.tbTrainingTopicExp
                                                        .picPath
                                                  }
                                                })
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.$zwxBase.verifyIsBlank(
                                          item.tbTrainingTopicExp.optionLayout
                                        ) ||
                                        item.tbTrainingTopicExp.optionLayout ===
                                          1
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "question-options-column"
                                              },
                                              [
                                                _vm._l(
                                                  item.tbTrainingTopicExp
                                                    .trainingOptionList,
                                                  function(opt) {
                                                    return [
                                                      item.optionValue ===
                                                        opt.rid &&
                                                      opt.ifAnswer === true
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "choose-right"
                                                            },
                                                            [
                                                              _c(
                                                                "el-radio",
                                                                {
                                                                  key: opt.rid,
                                                                  staticClass:
                                                                    "zwx-radio",
                                                                  attrs: {
                                                                    label:
                                                                      opt.rid,
                                                                    disabled: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.optionValue,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item,
                                                                        "optionValue",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "item.optionValue"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      opt.optionCode
                                                                    ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        opt.optionContent
                                                                      )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      item.optionValue ===
                                                        opt.rid &&
                                                      opt.ifAnswer === false
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "choose-error"
                                                            },
                                                            [
                                                              _c(
                                                                "el-radio",
                                                                {
                                                                  key: opt.rid,
                                                                  staticClass:
                                                                    "zwx-radio",
                                                                  attrs: {
                                                                    label:
                                                                      opt.rid,
                                                                    disabled: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.optionValue,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item,
                                                                        "optionValue",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "item.optionValue"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      opt.optionCode
                                                                    ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        opt.optionContent
                                                                      )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      item.optionValue !==
                                                      opt.rid
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "el-radio",
                                                                {
                                                                  key: opt.rid,
                                                                  staticClass:
                                                                    "zwx-radio",
                                                                  attrs: {
                                                                    label:
                                                                      opt.rid,
                                                                    disabled: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.optionValue,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item,
                                                                        "optionValue",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "item.optionValue"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      opt.optionCode
                                                                    ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        opt.optionContent
                                                                      )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                        item.tbTrainingTopicExp.optionLayout ===
                                        2
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "question-options-default"
                                              },
                                              [
                                                _vm._l(
                                                  item.tbTrainingTopicExp
                                                    .trainingOptionList,
                                                  function(opt) {
                                                    return [
                                                      item.optionValue ===
                                                        opt.rid &&
                                                      opt.ifAnswer === true
                                                        ? _c("div", [
                                                            _vm.$zwxBase.verifyIsNotBlank(
                                                              opt.picPath
                                                            )
                                                              ? _c("img", {
                                                                  staticStyle: {
                                                                    height:
                                                                      "90px",
                                                                    "margin-bottom":
                                                                      "20px",
                                                                    "margin-right":
                                                                      "60px",
                                                                    "padding-left":
                                                                      "28px"
                                                                  },
                                                                  attrs: {
                                                                    src:
                                                                      _vm.$store
                                                                        .state
                                                                        .file +
                                                                      opt.picPath
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.openFilePreview(
                                                                        opt.picPath
                                                                      )
                                                                    }
                                                                  }
                                                                })
                                                              : _vm._e(),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "choose-right",
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "28px"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "el-radio",
                                                                  {
                                                                    key:
                                                                      opt.rid,
                                                                    staticClass:
                                                                      "zwx-radio",
                                                                    attrs: {
                                                                      label:
                                                                        opt.rid,
                                                                      disabled:
                                                                        ""
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item.optionValue,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "optionValue",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "item.optionValue"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        opt.optionCode
                                                                      ) +
                                                                        " " +
                                                                        _vm._s(
                                                                          opt.optionContent
                                                                        )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ])
                                                        : _vm._e(),
                                                      item.optionValue ===
                                                        opt.rid &&
                                                      opt.ifAnswer === false
                                                        ? _c("div", [
                                                            _vm.$zwxBase.verifyIsNotBlank(
                                                              opt.picPath
                                                            )
                                                              ? _c("img", {
                                                                  staticStyle: {
                                                                    height:
                                                                      "90px",
                                                                    "margin-bottom":
                                                                      "20px",
                                                                    "margin-right":
                                                                      "60px",
                                                                    "padding-left":
                                                                      "28px"
                                                                  },
                                                                  attrs: {
                                                                    src:
                                                                      _vm.$store
                                                                        .state
                                                                        .file +
                                                                      opt.picPath
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.openFilePreview(
                                                                        opt.picPath
                                                                      )
                                                                    }
                                                                  }
                                                                })
                                                              : _vm._e(),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "choose-error",
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "28px"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "el-radio",
                                                                  {
                                                                    key:
                                                                      opt.rid,
                                                                    staticClass:
                                                                      "zwx-radio",
                                                                    attrs: {
                                                                      label:
                                                                        opt.rid,
                                                                      disabled:
                                                                        ""
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item.optionValue,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "optionValue",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "item.optionValue"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        opt.optionCode
                                                                      ) +
                                                                        " " +
                                                                        _vm._s(
                                                                          opt.optionContent
                                                                        )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ])
                                                        : _vm._e(),
                                                      item.optionValue !==
                                                      opt.rid
                                                        ? _c("div", [
                                                            _vm.$zwxBase.verifyIsNotBlank(
                                                              opt.picPath
                                                            )
                                                              ? _c("img", {
                                                                  staticStyle: {
                                                                    height:
                                                                      "90px",
                                                                    "margin-bottom":
                                                                      "20px",
                                                                    "margin-right":
                                                                      "60px",
                                                                    "padding-left":
                                                                      "28px"
                                                                  },
                                                                  attrs: {
                                                                    src:
                                                                      _vm.$store
                                                                        .state
                                                                        .file +
                                                                      opt.picPath
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.openFilePreview(
                                                                        opt.picPath
                                                                      )
                                                                    }
                                                                  }
                                                                })
                                                              : _vm._e(),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "28px"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "el-radio",
                                                                  {
                                                                    key:
                                                                      opt.rid,
                                                                    staticClass:
                                                                      "zwx-radio",
                                                                    attrs: {
                                                                      label:
                                                                        opt.rid,
                                                                      disabled:
                                                                        ""
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item.optionValue,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "optionValue",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "item.optionValue"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        opt.optionCode
                                                                      ) +
                                                                        " " +
                                                                        _vm._s(
                                                                          opt.optionContent
                                                                        )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ]
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "question-analysis",
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.changeAnalysis(
                                                  "judgeQuestionList",
                                                  i
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(" 试题解析 "),
                                            _c("i", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.$zwxBase.verifyIsBlank(
                                                      item.ifShowAnalysis
                                                    ) ||
                                                    item.ifShowAnalysis ===
                                                      false,
                                                  expression:
                                                    "$zwxBase.verifyIsBlank(item.ifShowAnalysis) || item.ifShowAnalysis === false"
                                                }
                                              ],
                                              staticClass: "el-icon-arrow-down"
                                            }),
                                            _c("i", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    item.ifShowAnalysis ===
                                                    true,
                                                  expression:
                                                    "item.ifShowAnalysis === true"
                                                }
                                              ],
                                              staticClass: "el-icon-arrow-up"
                                            })
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  item.ifShowAnalysis === true,
                                                expression:
                                                  "item.ifShowAnalysis === true"
                                              }
                                            ],
                                            staticClass:
                                              "question-analysis-text",
                                            staticStyle: { color: "#1DC482" }
                                          },
                                          [
                                            _vm._v(
                                              "正确选项：" +
                                                _vm._s(
                                                  _vm.getOptionAnswer(
                                                    item.tbTrainingTopicExp
                                                      .trainingOptionList
                                                  )
                                                )
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  item.ifShowAnalysis === true,
                                                expression:
                                                  "item.ifShowAnalysis === true"
                                              }
                                            ],
                                            staticClass:
                                              "question-analysis-text"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$zwxBase.verifyIsNotBlank(
                                                    item
                                                  )
                                                    ? item.tbTrainingTopicExp
                                                        .topicAnalysis
                                                    : ""
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]),
                                      i <
                                      _vm.examRecordDetailForm.judgeQuestionList
                                        .length -
                                        1
                                        ? _c("div", {
                                            staticClass: "divider-line"
                                          })
                                        : _vm._e()
                                    ]
                                  }
                                )
                              ],
                              2
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.examRecordDetailForm.scoreMode == 0
                  ? _c("div", { staticClass: "zwx-exam-record-main-left" }, [
                      _vm.$zwxBase.verifyIsNotBlank(
                        _vm.examRecordDetailForm.examTopicDeailList
                      )
                        ? _c(
                            "div",
                            { staticClass: "question-body" },
                            [
                              _vm._l(
                                _vm.examRecordDetailForm.examTopicDeailList,
                                function(item, i) {
                                  return [
                                    _c("div", { staticClass: "question" }, [
                                      _c(
                                        "div",
                                        { staticClass: "question-head" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "question-num" },
                                            [
                                              _vm._v(
                                                _vm._s(item.serialNumber) + "、"
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "question-title" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.tbTrainingTopicExp
                                                      .topicContent
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm.$zwxBase.verifyIsNotBlank(
                                        item.tbTrainingTopicExp.picPath
                                      )
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "question-pic",
                                              on: {
                                                click: function($event) {
                                                  return _vm.openFilePreview(
                                                    item.tbTrainingTopicExp
                                                      .picPath
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  width: "100%",
                                                  height: "100%"
                                                },
                                                attrs: {
                                                  src:
                                                    _vm.$store.state.file +
                                                    item.tbTrainingTopicExp
                                                      .picPath
                                                }
                                              })
                                            ]
                                          )
                                        : _vm._e(),
                                      (_vm.$zwxBase.verifyIsBlank(
                                        item.tbTrainingTopicExp.optionLayout
                                      ) ||
                                        item.tbTrainingTopicExp.optionLayout ===
                                          1) &&
                                      item.tbTrainingTopicExp.topicType == 2
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "question-multi-options-column"
                                            },
                                            [
                                              _c(
                                                "el-checkbox-group",
                                                {
                                                  staticClass:
                                                    "zwx-checkbox-group",
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value: item.optionValue,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "optionValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.optionValue"
                                                  }
                                                },
                                                [
                                                  _vm._l(
                                                    item.tbTrainingTopicExp
                                                      .trainingOptionList,
                                                    function(opt) {
                                                      return [
                                                        _vm.$zwxBase.verifyIsNotBlank(
                                                          item.optionValue
                                                        ) &&
                                                        item.optionValue.indexOf(
                                                          opt.rid
                                                        ) != -1 &&
                                                        opt.ifAnswer === true
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "multi-option choose-right"
                                                              },
                                                              [
                                                                _c(
                                                                  "el-checkbox",
                                                                  {
                                                                    key:
                                                                      opt.rid,
                                                                    attrs: {
                                                                      label:
                                                                        opt.rid
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        opt.optionCode
                                                                      ) +
                                                                        " " +
                                                                        _vm._s(
                                                                          opt.optionContent
                                                                        )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.$zwxBase.verifyIsNotBlank(
                                                          item.optionValue
                                                        ) &&
                                                        item.optionValue.indexOf(
                                                          opt.rid
                                                        ) != -1 &&
                                                        opt.ifAnswer === false
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "multi-option choose-error"
                                                              },
                                                              [
                                                                _c(
                                                                  "el-checkbox",
                                                                  {
                                                                    key:
                                                                      opt.rid,
                                                                    attrs: {
                                                                      label:
                                                                        opt.rid
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        opt.optionCode
                                                                      ) +
                                                                        " " +
                                                                        _vm._s(
                                                                          opt.optionContent
                                                                        )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.$zwxBase.verifyIsBlank(
                                                          item.optionValue
                                                        ) ||
                                                        item.optionValue.indexOf(
                                                          opt.rid
                                                        ) == -1
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "multi-option"
                                                              },
                                                              [
                                                                _c(
                                                                  "el-checkbox",
                                                                  {
                                                                    key:
                                                                      opt.rid,
                                                                    attrs: {
                                                                      label:
                                                                        opt.rid
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        opt.optionCode
                                                                      ) +
                                                                        " " +
                                                                        _vm._s(
                                                                          opt.optionContent
                                                                        )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      (_vm.$zwxBase.verifyIsBlank(
                                        item.tbTrainingTopicExp.optionLayout
                                      ) ||
                                        item.tbTrainingTopicExp.optionLayout ===
                                          1) &&
                                      item.tbTrainingTopicExp.topicType != 2
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "question-options-column"
                                            },
                                            [
                                              _vm._l(
                                                item.tbTrainingTopicExp
                                                  .trainingOptionList,
                                                function(opt) {
                                                  return [
                                                    item.optionValue ===
                                                      opt.rid &&
                                                    opt.ifAnswer === true
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "choose-right"
                                                          },
                                                          [
                                                            _c(
                                                              "el-radio",
                                                              {
                                                                key: opt.rid,
                                                                staticClass:
                                                                  "zwx-radio",
                                                                attrs: {
                                                                  label:
                                                                    opt.rid,
                                                                  disabled: ""
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.optionValue,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "optionValue",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "item.optionValue"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    opt.optionCode
                                                                  ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      opt.optionContent
                                                                    )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    item.optionValue ===
                                                      opt.rid &&
                                                    opt.ifAnswer === false
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "choose-error"
                                                          },
                                                          [
                                                            _c(
                                                              "el-radio",
                                                              {
                                                                key: opt.rid,
                                                                staticClass:
                                                                  "zwx-radio",
                                                                attrs: {
                                                                  label:
                                                                    opt.rid,
                                                                  disabled: ""
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.optionValue,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "optionValue",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "item.optionValue"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    opt.optionCode
                                                                  ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      opt.optionContent
                                                                    )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    item.optionValue !== opt.rid
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "el-radio",
                                                              {
                                                                key: opt.rid,
                                                                staticClass:
                                                                  "zwx-radio",
                                                                attrs: {
                                                                  label:
                                                                    opt.rid,
                                                                  disabled: ""
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.optionValue,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "optionValue",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "item.optionValue"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    opt.optionCode
                                                                  ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      opt.optionContent
                                                                    )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ]
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      item.tbTrainingTopicExp.optionLayout ===
                                        2 &&
                                      item.tbTrainingTopicExp.topicType == 2
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "question-multi-options-default"
                                            },
                                            [
                                              _c(
                                                "el-checkbox-group",
                                                {
                                                  staticClass:
                                                    "zwx-checkbox-group",
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value: item.optionValue,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "optionValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.optionValue"
                                                  }
                                                },
                                                _vm._l(
                                                  item.tbTrainingTopicExp
                                                    .trainingOptionList,
                                                  function(opt) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "cross-layout"
                                                      },
                                                      [
                                                        opt.picPath
                                                          ? _c("img", {
                                                              staticStyle: {
                                                                height: "90px",
                                                                "margin-bottom":
                                                                  "20px"
                                                              },
                                                              attrs: {
                                                                src:
                                                                  _vm.$store
                                                                    .state
                                                                    .file +
                                                                  opt.picPath
                                                              }
                                                            })
                                                          : _vm._e(),
                                                        _vm.$zwxBase.verifyIsNotBlank(
                                                          item.optionValue
                                                        ) &&
                                                        _vm.$zwxBase.verifyIsNotBlank(
                                                          item.optionValue.filter(
                                                            e => e === opt.rid
                                                          )
                                                        ) &&
                                                        opt.ifAnswer === true
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "multi-option choose-right"
                                                              },
                                                              [
                                                                _c(
                                                                  "el-checkbox",
                                                                  {
                                                                    key:
                                                                      opt.rid,
                                                                    attrs: {
                                                                      label:
                                                                        opt.rid
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        opt.optionCode
                                                                      ) +
                                                                        " " +
                                                                        _vm._s(
                                                                          opt.optionContent
                                                                        )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.$zwxBase.verifyIsNotBlank(
                                                          item.optionValue
                                                        ) &&
                                                        _vm.$zwxBase.verifyIsNotBlank(
                                                          item.optionValue.filter(
                                                            e => e === opt.rid
                                                          )
                                                        ) &&
                                                        opt.ifAnswer === false
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "multi-option choose-error"
                                                              },
                                                              [
                                                                _c(
                                                                  "el-checkbox",
                                                                  {
                                                                    key:
                                                                      opt.rid,
                                                                    attrs: {
                                                                      label:
                                                                        opt.rid
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        opt.optionCode
                                                                      ) +
                                                                        " " +
                                                                        _vm._s(
                                                                          opt.optionContent
                                                                        )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.$zwxBase.verifyIsBlank(
                                                          item.optionValue
                                                        ) ||
                                                        _vm.$zwxBase.verifyIsBlank(
                                                          item.optionValue.filter(
                                                            e => e === opt.rid
                                                          )
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "multi-option"
                                                              },
                                                              [
                                                                _c(
                                                                  "el-checkbox",
                                                                  {
                                                                    key:
                                                                      opt.rid,
                                                                    attrs: {
                                                                      label:
                                                                        opt.rid
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        opt.optionCode
                                                                      ) +
                                                                        " " +
                                                                        _vm._s(
                                                                          opt.optionContent
                                                                        )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      item.tbTrainingTopicExp.optionLayout ===
                                        2 &&
                                      item.tbTrainingTopicExp.topicType != 2
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "question-options-default"
                                            },
                                            [
                                              _vm._l(
                                                item.tbTrainingTopicExp
                                                  .trainingOptionList,
                                                function(opt) {
                                                  return [
                                                    item.optionValue ===
                                                      opt.rid &&
                                                    opt.ifAnswer === true
                                                      ? _c("div", [
                                                          _vm.$zwxBase.verifyIsNotBlank(
                                                            opt.picPath
                                                          )
                                                            ? _c("img", {
                                                                staticStyle: {
                                                                  height:
                                                                    "90px",
                                                                  "margin-bottom":
                                                                    "20px",
                                                                  "margin-right":
                                                                    "60px",
                                                                  "padding-left":
                                                                    "28px"
                                                                },
                                                                attrs: {
                                                                  src:
                                                                    _vm.$store
                                                                      .state
                                                                      .file +
                                                                    opt.picPath
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.openFilePreview(
                                                                      opt.picPath
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            : _vm._e(),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "choose-right",
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "28px"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "el-radio",
                                                                {
                                                                  key: opt.rid,
                                                                  staticClass:
                                                                    "zwx-radio",
                                                                  attrs: {
                                                                    label:
                                                                      opt.rid,
                                                                    disabled: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.optionValue,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item,
                                                                        "optionValue",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "item.optionValue"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      opt.optionCode
                                                                    ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        opt.optionContent
                                                                      )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ])
                                                      : _vm._e(),
                                                    item.optionValue ===
                                                      opt.rid &&
                                                    opt.ifAnswer === false
                                                      ? _c("div", [
                                                          _vm.$zwxBase.verifyIsNotBlank(
                                                            opt.picPath
                                                          )
                                                            ? _c("img", {
                                                                staticStyle: {
                                                                  height:
                                                                    "90px",
                                                                  "margin-bottom":
                                                                    "20px",
                                                                  "margin-right":
                                                                    "60px",
                                                                  "padding-left":
                                                                    "28px"
                                                                },
                                                                attrs: {
                                                                  src:
                                                                    _vm.$store
                                                                      .state
                                                                      .file +
                                                                    opt.picPath
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.openFilePreview(
                                                                      opt.picPath
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            : _vm._e(),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "choose-error",
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "28px"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "el-radio",
                                                                {
                                                                  key: opt.rid,
                                                                  staticClass:
                                                                    "zwx-radio",
                                                                  attrs: {
                                                                    label:
                                                                      opt.rid,
                                                                    disabled: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.optionValue,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item,
                                                                        "optionValue",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "item.optionValue"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      opt.optionCode
                                                                    ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        opt.optionContent
                                                                      )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ])
                                                      : _vm._e(),
                                                    item.optionValue !== opt.rid
                                                      ? _c("div", [
                                                          _vm.$zwxBase.verifyIsNotBlank(
                                                            opt.picPath
                                                          )
                                                            ? _c("img", {
                                                                staticStyle: {
                                                                  height:
                                                                    "90px",
                                                                  "margin-bottom":
                                                                    "20px",
                                                                  "margin-right":
                                                                    "60px",
                                                                  "padding-left":
                                                                    "28px"
                                                                },
                                                                attrs: {
                                                                  src:
                                                                    _vm.$store
                                                                      .state
                                                                      .file +
                                                                    opt.picPath
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.openFilePreview(
                                                                      opt.picPath
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            : _vm._e(),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "28px"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "el-radio",
                                                                {
                                                                  key: opt.rid,
                                                                  staticClass:
                                                                    "zwx-radio",
                                                                  attrs: {
                                                                    label:
                                                                      opt.rid,
                                                                    disabled: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.optionValue,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item,
                                                                        "optionValue",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "item.optionValue"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      opt.optionCode
                                                                    ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        opt.optionContent
                                                                      )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "question-analysis",
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.changeAnalysis(
                                                "examTopicDeailList",
                                                i
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(" 试题解析 "),
                                          _c("i", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.$zwxBase.verifyIsBlank(
                                                    item.ifShowAnalysis
                                                  ) ||
                                                  item.ifShowAnalysis === false,
                                                expression:
                                                  "$zwxBase.verifyIsBlank(item.ifShowAnalysis) || item.ifShowAnalysis === false"
                                              }
                                            ],
                                            staticClass: "el-icon-arrow-down"
                                          }),
                                          _c("i", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  item.ifShowAnalysis === true,
                                                expression:
                                                  "item.ifShowAnalysis === true"
                                              }
                                            ],
                                            staticClass: "el-icon-arrow-up"
                                          })
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                item.ifShowAnalysis === true,
                                              expression:
                                                "item.ifShowAnalysis === true"
                                            }
                                          ],
                                          staticClass: "question-analysis-text",
                                          staticStyle: { color: "#1DC482" }
                                        },
                                        [
                                          _vm._v(
                                            "正确选项：" +
                                              _vm._s(
                                                _vm.getOptionAnswer(
                                                  item.tbTrainingTopicExp
                                                    .trainingOptionList
                                                )
                                              )
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                item.ifShowAnalysis === true,
                                              expression:
                                                "item.ifShowAnalysis === true"
                                            }
                                          ],
                                          staticClass: "question-analysis-text"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$zwxBase.verifyIsNotBlank(
                                                  item
                                                )
                                                  ? item.tbTrainingTopicExp
                                                      .topicAnalysis
                                                  : ""
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]),
                                    i <
                                    _vm.examRecordDetailForm.examTopicDeailList
                                      .length -
                                      1
                                      ? _c("div", {
                                          staticClass: "divider-line"
                                        })
                                      : _vm._e()
                                  ]
                                }
                              )
                            ],
                            2
                          )
                        : _vm._e()
                    ])
                  : _vm._e()
              ]
            ),
            _c("div", { staticClass: "zwx-exam-record-main-right" }, [
              _c("div", { staticClass: "course-name-text" }, [
                _vm._v(_vm._s(_vm.examRecordDetailForm.examName))
              ]),
              _vm.examRecordDetailForm.ifComplete === true
                ? _c("div", { staticClass: "exam-result-complete" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "exam-score-label" }, [
                      _vm._v(" 考试成绩 "),
                      _c("span", { staticClass: "exam-score-num" }, [
                        _vm._v(
                          " " + _vm._s(_vm.examRecordDetailForm.examScore) + " "
                        ),
                        _c("span", { staticStyle: { "font-size": "16px" } }, [
                          _vm._v("分")
                        ])
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm.examRecordDetailForm.ifComplete === false
                ? _c("div", { staticClass: "exam-result-no-complete" }, [
                    _vm._m(1),
                    _c("div", { staticClass: "exam-score-label" }, [
                      _vm._v(" 考试成绩 "),
                      _c("span", { staticClass: "exam-score-num" }, [
                        _vm._v(
                          " " + _vm._s(_vm.examRecordDetailForm.examScore) + " "
                        ),
                        _c("span", { staticStyle: { "font-size": "16px" } }, [
                          _vm._v("分")
                        ])
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm._m(2),
              _c(
                "div",
                { staticClass: "answer-detail" },
                [
                  _c(
                    "zwx-scrollbar-y",
                    { ref: "answerScrollbar", staticStyle: { height: "100%" } },
                    [
                      _vm._l(_vm.examRecordDetailForm.answerList, function(
                        item
                      ) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "answer-card",
                              style:
                                item.ifCorrect !== true
                                  ? "background:#FA4A39"
                                  : ""
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "#ffffff",
                                    "line-height": "22px"
                                  }
                                },
                                [_vm._v(_vm._s(item.serialNumber))]
                              )
                            ]
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ])
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "complete-label" }, [
      _c("i", {
        staticClass: "filled chis-icon-filled-completed",
        staticStyle: { color: "#1DC482", "margin-right": "2px" }
      }),
      _vm._v(" 通过 ")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "no-complete-label" }, [
      _c("i", {
        staticClass: "filled chis-icon-filled-failed",
        staticStyle: { color: "#EC4726", "margin-right": "2px" }
      }),
      _vm._v(" 未通过 ")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "answer-sheet" }, [
      _c("div", { staticClass: "answer-sheet-text" }, [_vm._v("答题卡")]),
      _c("div", { staticClass: "sample-text" }, [
        _c("div", {
          staticClass: "answer-tag",
          staticStyle: { background: "#1DC482" }
        }),
        _c("div", { staticClass: "tag-text" }, [_vm._v("答对")]),
        _c("div", {
          staticClass: "answer-tag",
          staticStyle: { background: "#FA4A39" }
        }),
        _c("div", { staticClass: "tag-text" }, [_vm._v("答错")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }