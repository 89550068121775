var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.fileClass },
    [
      _c(
        "el-form-item",
        {
          attrs: {
            "label-width": _vm.labelwidth,
            label: _vm.label,
            prop: _vm.fileProp,
            rules: _vm.rules
          }
        },
        [
          _c(
            "file-upload",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.ifDetail && _vm.fileList.length < _vm.limit,
                  expression: "!ifDetail&&fileList.length<limit"
                }
              ],
              ref: "fileUpload",
              attrs: {
                accept: _vm.accept,
                limit: _vm.limit,
                size: _vm.size,
                sizeInfo: _vm.sizeInfo
              },
              on: {
                showFileList: _vm.ShowFileList,
                success: _vm.fileUploadSuccess
              }
            },
            [
              _vm.ifUploadButton
                ? _c(
                    "el-button",
                    {
                      staticClass: "zwx-button zwx-button-icontext-26",
                      attrs: { icon: "el-icon-upload2" }
                    },
                    [_vm._v("上传")]
                  )
                : _vm._e(),
              _vm._t("default")
            ],
            2
          ),
          _vm.ifUploadButton && !_vm.ifDetail && _vm.fileList.length < _vm.limit
            ? _c("br")
            : _vm._e(),
          _vm.$zwxBase.verifyIsNotBlank(_vm.fileList)
            ? _c(
                "div",
                { staticClass: "show-file" },
                _vm._l(_vm.fileList, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticStyle: { width: "fit-content" },
                      on: {
                        mouseenter: function($event) {
                          return _vm.mouseenter(item)
                        },
                        mouseleave: function($event) {
                          return _vm.mouseleave(item)
                        }
                      }
                    },
                    [
                      _c("i", {
                        class: _vm.getPrefix(item),
                        staticStyle: { "margin-right": "3px" }
                      }),
                      _c(
                        "el-button",
                        {
                          staticClass: "zwx-button zwx-button-text-26",
                          on: {
                            click: function($event) {
                              return _vm.openFilePreview(_vm.fileList, index)
                            }
                          }
                        },
                        [_vm._v(_vm._s(item.fileName || "查看附件"))]
                      ),
                      !_vm.ifDetail && !item.fileName
                        ? _c(
                            "el-button",
                            {
                              staticClass: "zwx-button zwx-button-text-26",
                              attrs: { type: "danger" },
                              on: {
                                click: function($event) {
                                  return _vm.deletionPicFile(index)
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        : _vm._e(),
                      !_vm.ifDetail && item.fileName
                        ? _c("span", [
                            _c(
                              "span",
                              {
                                staticClass: "el-upload-list__item check",
                                style: item.check
                                  ? "margin-left:5px;display:none;"
                                  : "margin-left:5px;cursor:pointer"
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "el-icon-upload-success el-icon-circle-check"
                                })
                              ]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "check",
                                style: item.check
                                  ? "margin-left:5px;cursor:pointer"
                                  : "margin-left:5px;display:none;"
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-close",
                                  on: {
                                    click: function($event) {
                                      return _vm.deletionPicFile(index, 1)
                                    }
                                  }
                                })
                              ]
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e()
        ],
        1
      ),
      _c("file-preview", { ref: "filePreview" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }