/**
 * 获取码表数据源
 * */
let getSimpleCodeListByCodeTypeCodes = (codeTypeCodes, callback) => {
  if (window.vm.$zwxBase.verifyIsNotBlank(codeTypeCodes)) {
    let param = {
      codeTypeCodes: codeTypeCodes,
    }
    window.vm.$system.get(
      window.vm.$store.state.api + '/systematic/getSimpleCodeListByCodeTypeCodesMap-0',
      param,
      false,
      false,
      data => {
        callback(data.resultMap)
      },
      () => {}
    )
  }
}

let manyListFul = (data, pid) => {
  //递归方法 生成 json tree 数据
  var result = [],
    temp,
    obj
  data.some(e => {
    let LevelNoArr = e.codeLevelNo.split('.')
    if ((e.codeLevelNo == e.codeNo && pid == '') || LevelNoArr[LevelNoArr.length - 2] == pid) {
      obj = { ...e }
      temp = manyListFul(data, e.codeNo)
      if (temp.length > 0) {
        obj.children = temp
      }
      result.push(obj)
    }
  })
  return result
}

export default { getSimpleCodeListByCodeTypeCodes, manyListFul }
