import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import views from './view'

import packages from './package'
import { ZwxCookie,System } from 'vue-el-web'

Vue.use(VueRouter)

const api = store.state.api
const baseUrl = store.state.baseUrl

const routes = views.concat(packages)

const router = new VueRouter({
  routes,
})

let routerTo
let routerFrom
let routerNext

// 路由权限判断
router.beforeEach((to, from, next) => {
  // 是登录页，清除登录信息
  if (to.path === '/login') {
    ZwxCookie.delCookie('msTokenId')
  }
  // 确定与服务器的时间差
  routerTo = to
  routerFrom = from
  routerNext = next
  const internetTimeDiff = ZwxCookie.getCookie('internetTimeDiff')
  if (internetTimeDiff !== undefined && internetTimeDiff != null) {
    verifyUser()
  } else {
    System.post(api + '/time', {}, false, true, timeSuccess)
  }
  // 无需权限验证，直接进入页面
  if (to.meta.noRequireAuth) {
    next()
    return
  }

  // 没有tokenId，则一定要noRequireAuth
  if (!ZwxCookie.getCookie('msTokenId')) {
    console.log('没有tokenId，则一定要noRequireAuth')
    window.top.location.replace(baseUrl)
    return
  }

})

router.afterEach(to => {
  if (to.meta.title) store.commit('BREADCRUMBS', { title: to.meta.title, name: to.name })
})

export default router

const timeSuccess = data => {
  ZwxCookie.addCookie('internetTimeDiff', (Number(data.time) + Number(data.internetTimeDiff) - Date.now()).toString())
  verifyUser()
}

const verifyUser = () => {
  console.log(routerTo.meta)
  if (routerTo.meta.noMenuRoute) {
    let data = {}
    System.post(api + '/systematic/verifyUser-1', data, true, true, verifyUserSuccess, error)
  } else if (routerTo.meta.menuRoute) {
    if (store.state.users.rid) {
      routerNext()
      return
    }
    let data = { menuUrl: routerTo.path }
    System.post(api + '/systematic/verifyUserMenu-1', data, true, true, verifyUserMenuSuccess, error)
  } else if (routerFrom.name === null) {
    return
  } else {
    routerNext()
  }
}

const verifyUserSuccess = data => {
  if (data.type === '00') {
    store.commit('INIT_USER', data.userInfo)
    routerNext()
    nothingDoingTime()
  } else {
    delCookie()
  }
}

const verifyUserMenuSuccess = data => {
  if (data.type === '00') {
    store.commit('INIT_USER', data.userInfo)
    routerTo.meta.title = data.menu.menuTitle
   
    // store.commit('INIT_BTNS', data.btnList)
    routerNext()
    nothingDoingTime()
  } else {
    delCookie()
  }
}

const error = () => {
  if (routerTo.path === '/default') {
    window.top.location.replace(baseUrl)
  } else {
    System.notify('错误', '网络连接失败', 'error')
  }
}

const delCookie = () => {
  // ZwxCookie.delCookie('msTokenId')
  window.top.location.replace(baseUrl)
}

const nothingDoingTime = () => {
  document.onmousemove = doingTime
  document.ontouchmove = doingTime
  document.onmousedown = doingTime
  document.onkeydown = doingTime
}

const doingTime = () => {
  ZwxCookie.addCookie('nothingDoingTime', Date.now().toString())
}
