<template>
  <div>
    <el-form class="zwx-form edit-form" key="msForm" ref="msForm" :model="msForm" :rules="rules" label-position="right" @submit.native.prevent>
      <base-headline title="基本信息" class="no-top" />
      <div style="margin-top:10px">
        <div class="condition-row">
          <!--          <el-form-item label-width="110px" label="编码：" prop="topicCode">
            <el-input style="width:260px !important" class="zwx-input" v-model.trim="msForm.topicCode" placeholder="请输入" clearable maxlength="20" />
          </el-form-item>-->
          <div class="mstype">
            <el-form-item label-width="110px" label="类别：" prop="pointType">
              <cascader-options-multi ref="mscCascader" v-model="msForm.pointType" :options="simpleCodeList" dataNameFiled="codeName" dataCodeFiled="codeNo" dataUpCodeFiled="children" codeLevelNo="codeLevelNo" width="260px" :showFullTag="false" :collapsetags="true" v-on:propEvent="propEvent"></cascader-options-multi>
            </el-form-item>
          </div>
        </div>
        <div class="condition-row">
          <el-form-item label="题型：" label-width="110px" prop="topicType">
            <el-select class="zwx-select topic-select" popper-class="zwx-select-popper"  v-model="msForm.topicType" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label-width="130px" label="选项排列方式：" prop="optionLayout">
            <el-popover placement="top" trigger="hover">
              <div>1、若选项都为图片，则默认纵向排列<br />2、若选项文字过多，建议纵向排列</div>
              <i slot="reference" class="el-icon-info" style="color:#606266;margin-right:10px"></i>
            </el-popover>
            <el-radio-group class="zwx-radio-group" v-model="msForm.optionLayout" style="width: 200px !important;" :disabled="optionDisable">
              <el-radio class="zwx-radio" :label="1">纵向</el-radio>
              <el-radio class="zwx-radio" :label="2">横向</el-radio>
            </el-radio-group>
          </el-form-item>

        </div>
        <div class="condition-row">
          <el-form-item label-width="110px" label="题目：" prop="topicContent">
            <el-input style="width:640px !important" class="zwx-input" v-model.trim="msForm.topicContent" placeholder="请输入" clearable maxlength="500" />
          </el-form-item>
        </div>
        <div class="condition-row">
          <el-form-item label-width="110px" label="题目解析说明：" prop="topicAnalysis">
            <el-input class="zwx-textarea" type="textarea" v-model.trim="msForm.topicAnalysis" style="width: 640px !important;" :autosize="{ minRows: 5 }" maxlength="2000" resize="none" placeholder="请输入" clearable />
          </el-form-item>
        </div>
        <div class="condition-row">
          <el-form-item label-width="110px" label="图片上传：" prop="annexList">
            <file-upload ref="picFileRef" accept=".png,.jpeg,.jpg,.JPG,.JPEG,.PNG," @showFileList="picFileShowFileList" @success="picFileUploadSuccess" :size="50 * 1024 * 1024" sizeInfo="50M">
              <el-button class="zwx-button zwx-button-text-26" icon="el-icon-upload2" v-if="$zwxBase.verifyIsBlank(msForm.annexList)">上传</el-button>
            </file-upload>
            <div class="show-file" v-if="$zwxBase.verifyIsNotBlank(msForm.annexList)">
              <el-button class="zwx-button zwx-button-text-26" @click="openFilePreview1(msForm.annexList, 0)">查看</el-button>
              <el-button class="zwx-button zwx-button-text-26" type="danger" @click="deletionPicFile(0)">删除</el-button>
            </div>
          </el-form-item>
        </div>
      </div>
      <base-headline title="题目选项" class="no-bottom">
        <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="addOption">添加</el-button>
      </base-headline>
      <el-table class="zwx-table" key="msForm.trainingOptionList" :data="msForm.trainingOptionList" border stripe tooltip-effect="light">
        <el-table-column prop="optionCode" label="选项" width="200" header-align="center" align="center">
          <template slot="header">
            <span>选项</span>
            <el-popover placement="right" title="" width="200" trigger="hover">
              <div>
                <div>
                  <span>A.****</span>
                </div>
                <div>
                  <span>B.****</span>
                </div>
                <div>
                  <span>C.****</span>
                </div>
                <div>
                  <span>D.****</span>
                </div>
              </div>
              <i class="outline chis-icon-outline-information1" style="color: #FF8327;margin-left: 5px;cursor: pointer" slot="reference"></i>
            </el-popover>
          </template>
          <template slot-scope="scope">
            <el-form-item :id="'optionCode' + scope.$index" :prop="'trainingOptionList.' + scope.$index + '.optionCode'" :rules="rules.optionCode">
              <el-input class="zwx-input" v-model="scope.row.optionCode" placeholder="" clearable maxlength="20" />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="optionContent" label="选项内容" width="600" header-align="center" align="center">
          <template slot-scope="scope">
            <el-form-item :id="'optionContent' + scope.$index" :prop="'trainingOptionList.' + scope.$index + '.optionContent'" :rules="rules.optionContent">
              <el-input class="zwx-input" v-model="scope.row.optionContent" placeholder="" clearable maxlength="200" />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="ifAnswer" label="是否答案" width="150" header-align="center" align="center">
          <template slot-scope="scope">
            <el-form-item :id="'ifAnswer' + scope.$index" :prop="'trainingOptionList.' + scope.$index + '.ifAnswer'">
              <el-checkbox class="zwx-checkbox if-answer" v-model="scope.row.ifAnswer"></el-checkbox>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column width="160" prop="picPath" label="图片" header-align="center" align="center">
          <template slot-scope="scope">
            <el-form-item :prop="'trainingOptionList.' + scope.$index + '.picPath'">
              <file-upload :ref="'msPositive' + scope.row.uuid" @showFileList="clickPositive($event, scope.$index, scope.row.uuid)" @success="clickPositiveSuccess($event, scope.$index)" @error="clickError()" accept=".png,.jpeg,.jpg,.JPG,.JPEG,.PNG" :size="50 * 1024 * 1024" sizeInfo="50M">
                <el-button class="zwx-button zwx-button-text-26 " type="text" v-if="$zwxBase.verifyIsBlank(msForm.trainingOptionList[scope.$index].optionPicList) || msForm.trainingOptionList[scope.$index].optionPicList.length < 1">上传</el-button>
              </file-upload>
              <span class="show-file" v-if="msForm.trainingOptionList[scope.$index].optionPicList.length > 0">
                <span v-for="(item, index) in msForm.trainingOptionList[scope.$index].optionPicList" :key="item.fileName" @mouseenter="mouseenter(scope.$index, index)" @mouseleave="mouseleave(scope.$index, index)">
                  <el-button class="zwx-button zwx-button-text-26" @click="openFilePreview1(msForm.trainingOptionList[scope.$index].optionPicList, 0)">查看</el-button>
                  <el-button class="zwx-button zwx-button-text-26" type="danger" @click="deletionPositive(scope.row.uuid, scope.$index, index)">删除</el-button>
                </span>
              </span>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="160" header-align="left" align="left">
          <template slot-scope="scope">
            <el-button class="zwx-button zwx-button-text-26" type="danger" @click="deleteItem(scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <fixed-footer>
      <el-button class="zwx-button zwx-button-32" type="primary" @click="addOrModifyTbEmployerInvestigation" :disabled="saveButtonDisabled">保存</el-button>
    </fixed-footer>
  </div>
</template>

<script>
export default {
  name: 'TopicManageEdit',
  data() {
    return {
      api: this.$store.state.api,
      view: true,
      type: this.$route.params.type,
      uuid: '',
      optionDisable: false, // 是否禁用排列方式
      msForm: {
        pointType: '',
        pointTypeName: '',
        topicContent: '',
        topicCode: '',
        topicType: '',
        topicAnalysis: '',
        optionLayout: 1,
        picPath: '',
        ifEnable: '',
        annexList: [],
        trainingOptionList: [],
      },
      pointTypeList: [],
      simpleCodeList: [],
      uploadCount: 0,
      options: [
        {
          value: 1,
          label: '单选',
        },
        {
          value: 2,
          label: '多选',
        },
        {
          value: 3,
          label: '判断题',
        },
      ],
      loading: null,
      // 校验规则
      rules: {
        topicCode: [{ required: true, message: '请输入编码', trigger: 'blur' }],
        topicType: [{ required: true, message: '请选择题型', trigger: 'change' }],
        pointType: [{ required: true, message: '请选择类别', trigger: ['change', 'blur'] }],
        optionLayout: [{ required: true, message: '请选择排列方式', trigger: 'change' }],
        topicContent: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        optionCode: [{ required: true, validator: this.optionCodeValidate, trigger: ['change', 'blur'] }],

        optionContent: [{ required: true, message: '请输入选项内容', trigger: 'blur' }],
      },
      msFormOld: null,
      folder: '/onlineTraining', //文件存储路径
      config: {},
    }
  },
  watch: {
    'msForm.trainingOptionList': {
      deep: true,
      handler(newVal) {
        // 检查trainingOptionList中每个元素的picPath是否都不为空
        const allPicPathsSet = newVal.filter(item => item.optionPicList !== null && item.optionPicList.length > 0).length > 0
        // 若选项全为图片，则禁用选项排列方式，并且默认选项排列方式改为纵向
        this.optionDisable = allPicPathsSet
        if (allPicPathsSet) {
          this.msForm.optionLayout = 1
        }
      },
    },
  },
  created() { },
  mounted() {
    this.$SimpleCodeTools.getConfig(data => {
      this.config = data
    })
    // 判断添加 还是编辑
    this.pageRouter()
    // 获取培训类型数据源
    this.getPointTypeList()
  },
  activated() {
    if (this.$route.params.fresh) this.query()
  },
  computed: {
    saveButtonDisabled() {
      if (JSON.stringify(this.msForm) !== this.msFormOld) {
        return false
      } else {
        return true
      }
    },
  },
  methods: {
    /**
     * 培训类型数据源
     */
    getPointTypeList() {
      var list = []
      this.$simpleCodeTools.getSimpleCodeListByCodeTypeCodes('4020', data => {
        list = data['4020']
        this.pointTypeList = list
        if (this.$zwxBase.verifyIsNotBlank(list)) {
          this.simpleCodeList = this.manyListFul(list, '')
        }
      })
    },
    manyListFul(data, pid) {
      //递归方法 生成 json tree 数据
      var result = [],
        temp,
        obj
      data.some(e => {
        let LevelNoArr = e.codeLevelNo.split('.')
        if ((e.codeLevelNo == e.codeNo && pid == '') || LevelNoArr[LevelNoArr.length - 2] == pid) {
          obj = { ...e }
          temp = this.manyListFul(data, e.codeNo)
          if (temp.length > 0) {
            obj.children = temp
          }
          result.push(obj)
        }
      })
      return result
    },
    //组件返回监听
    propEvent: function (somedata) {
      this.$set(this.msForm, 'pointType', somedata)
    },
    /**
     * 页面路由回调
     */
    pageRouter() {
      if (this.type === 'add') {
        this.$emit('breadcrumb', '添加', true)
        this.uploadCount = 0
        this.msFormOld = JSON.stringify(this.msForm)
        this.uuid = ''
      }
      if (this.type === 'edit') {
        this.$emit('breadcrumb', '编辑', true)
        this.uploadCount = 0
        this.uuid = this.$route.params.uuid
        this.getDetail(this.uuid)
      }
    },
    /**
     * 获取详情
     */
    getDetail(uuid) {
      let data = {
        uuid: uuid,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/training/platform/getTrainingTopic-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.msForm.uuid = data.tbTrainingTopicExp.uuid
            this.msForm.pointType = data.tbTrainingTopicExp.pointType
            this.msForm.pointTypeName = data.tbTrainingTopicExp.pointTypeName
            this.msForm.topicCode = data.tbTrainingTopicExp.topicCode
            this.msForm.topicType = data.tbTrainingTopicExp.topicType
            this.msForm.topicContent = data.tbTrainingTopicExp.topicContent
            this.msForm.topicAnalysis = data.tbTrainingTopicExp.topicAnalysis
            this.msForm.optionLayout = data.tbTrainingTopicExp.optionLayout
            this.msForm.picPath = data.tbTrainingTopicExp.picPath
            this.msForm.ifEnable = data.tbTrainingTopicExp.ifEnable
            this.msForm.annexList = []
            this.msForm.trainingOptionList = []
            if (this.$zwxBase.verifyIsNotBlank(data.tbTrainingTopicExp.picPath)) {
              this.msForm.annexList.push({
                fileIconShow: 'ms-file-jpg-icon',
                check: false,
                filePath: data.tbTrainingTopicExp.picPath,
              })
            }
            if (this.$zwxBase.verifyIsNotBlank(data.tbTrainingTopicExp.trainingOptionList)) {
              this.msForm.trainingOptionList = data.tbTrainingTopicExp.trainingOptionList
              this.msForm.trainingOptionList.forEach(item => {
                item.uuid = this.$system.uuid()
                this.$set(item, 'optionPicList', [])
                if (this.$zwxBase.verifyIsNotBlank(item.picPath)) {
                  item.optionPicList.push({
                    fileIconShow: 'ms-file-jpg-icon',
                    check: false,
                    filePath: item.picPath,
                  })
                }
              })
            }
            this.msFormOld = JSON.stringify(this.msForm)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    // 获取上传题目图片文件的UID
    generateFilesUidPic() {
      this.$emit('loading', true)
      let data = {
        folder: 'saas/' + this.config.domainPrefix + '/course',
      }
      this.$system.get(
        this.api + '/systematic/tools/getAnnexUploadUid-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.$refs.picFileRef.submit(data.annexUploadUid)
            this.$emit('loading', false)
          } else {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        {}
      )
    },
    /**
     * 题目图片文件上传成功回调
     */
    picFileUploadSuccess(fileList) {
      // 文件集合
      if (this.$zwxBase.verifyIsNotBlank(this.msForm.annexList) && this.$zwxBase.verifyIsNotBlank(fileList)) {
        for (let i in this.msForm.annexList) {
          for (let j in fileList) {
            if (this.msForm.annexList[i].fileName === fileList[j].fileName) {
              this.msForm.annexList[i].filePath = fileList[j].filePath
            }
          }
        }
      }
      /*this.msForm.trainingOptionList.forEach(item => {
        this.generateFilesUid(item.uuid)
      })*/
    },
    /**
     * 文件上传失败回调
     */
    picFileUploadSuccessError() {
      this.$system.notify('失败', '文件上传失败', 'error')
    },
    /**
     * 图片文件上传显示回调
     */
    picFileShowFileList(showList) {
      showList.forEach(item => {
        let flag = this.msForm.annexList.some(e => e.fileName == item)
        if (flag) {
          return
        }
        this.msForm.annexList.push({
          fileName: item,
          fileIconShow: this.$zwxBase.dealfilePrefix(item),
          check: false,
        })
      })
      if (showList && showList.length > 0) {
        this.generateFilesUidPic()
      }
    },
    /**
     * 删除题目图片文件
     */
    deletionPicFile(index) {
      let fileName = this.msForm.annexList[index].fileName
      let filePath = this.msForm.annexList[index].filePath
      this.msForm.annexList.splice(index, 1)
      if (this.$zwxBase.verifyIsBlank(filePath)) {
        this.$refs.picFileRef.deleteIndex(index)
      }
      let fileList = this.$refs.picFileRef.showFileList || []
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs.picFileRef.deleteIndex(j)
            fileList = this.$refs.picFileRef.showFileList || []
            continue
          }
        }
      }
    },
    // 选项图片上传显示回调
    clickPositive(showList, index, uuid) {
      showList.forEach(item => {
        let flag = this.msForm.trainingOptionList[index].optionPicList.some(e => e.fileName == item)
        if (flag) {
          return
        }
        this.msForm.trainingOptionList[index].optionPicList.push({
          fileName: item,
          fileIconShow: 'ms-file-jpg-icon',
          check: false,
        })
      })
      if (showList && showList.length > 0) {
        this.generateFilesUid(uuid)
      }
    },
    /**
     * 添加选项
     */
    addOption() {
      let data = {
        uuid: '',
        optionCode: '',
        optionContent: '',
        ifAnswer: '',
        optionPicList: [],
        uuid: this.$system.uuid(),
      }
      this.msForm.trainingOptionList.push(data)
    },
    /**
     * 删除选项
     */
    deleteItem(index) {
      this.msForm.trainingOptionList.splice(index, 1)
    },
    /**
     * 保存或更新
     */
    addOrModifyTbEmployerInvestigation() {
      this.$refs.msForm.validate(valid => {
        if (valid) {
          //单选题至少添加2个选项，多选题至少添加3个选项，判断题型只允许添加2个选项
          if (this.msForm.topicType == 1 && this.msForm.trainingOptionList.length < 2) {
            this.$system.notify('错误', '单选题至少添加2个选项', 'info')
            return
          }
          if (this.msForm.topicType == 2 && this.msForm.trainingOptionList.length < 3) {
            this.$system.notify('错误', '多选题至少添加3个选项', 'info')
            return
          }
          if (this.msForm.topicType == 3 && this.msForm.trainingOptionList.length != 2) {
            this.$system.notify('错误', '判断题型只允许添加2个选项', 'info')
            return
          }
          //是否答案非必填，单选题、判断题有且只能勾选1项，多选题至少勾选2项
          if (this.msForm.topicType == 1 || this.msForm.topicType == 3) {
            let i = 0
            this.msForm.trainingOptionList.forEach(item => {
              if (item.ifAnswer) {
                i++
              }
            })
            if (i != 1) {
              this.$system.notify('错误', '答案有且只能勾选1项', 'info')
              return
            }
          }
          if (this.msForm.topicType == 2) {
            let i = 0
            this.msForm.trainingOptionList.forEach(item => {
              if (item.ifAnswer) {
                i++
              }
            })
            if (i < 2) {
              this.$system.notify('错误', '答案至少勾选2项', 'info')
              return
            }
          }
          //this.generateFilesUidPic()
          this.addTbTrainingTopicFrom()
        }
      })
    },
    /**
     * 获取选项文件上传uuid
     */
    generateFilesUid(uuid) {
      let data = {
        folder: 'saas/' + this.config.domainPrefix + '/course',
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/systematic/tools/getAnnexUploadUid-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.$refs['msPositive' + uuid].submit(data.annexUploadUid)
            this.$emit('loading', false)
          } else {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    // 选项图片 上传成功回调
    clickPositiveSuccess(fileList, index) {
      this.uploadCount += 1
      // // 文件集合
      let optionPicList = this.msForm.trainingOptionList[index].optionPicList
      if (this.$zwxBase.verifyIsNotBlank(optionPicList) && this.$zwxBase.verifyIsNotBlank(fileList)) {
        for (let i in optionPicList) {
          for (let j in fileList) {
            if (optionPicList[i].fileName === fileList[j].fileName) {
              optionPicList[i].filePath = fileList[j].filePath
            }
          }
        }
      }
      /*if (this.uploadCount === this.msForm.trainingOptionList.length) {
        this.addTbTrainingTopicFrom(fileList)
      }*/
    },
    /**
     * 点击确定后，保存添加/编辑操作 数据
     */
    addTbTrainingTopicFrom(fileList) {
      this.msForm.trainingOptionList.forEach(item => {
        item.picPath = item.optionPicList.length > 0 ? item.optionPicList[0].filePath : ''
      })
      let data = {
        uuid: this.uuid,
        topicType: this.msForm.topicType,
        topicCode: this.msForm.topicCode,
        pointType: this.msForm.pointType,
        topicContent: this.msForm.topicContent,
        topicAnalysis: this.msForm.topicAnalysis,
        optionLayout: this.msForm.optionLayout,
        picPath: this.msForm.annexList.length > 0 ? this.msForm.annexList[0].filePath : '',
        trainingOptionList: this.msForm.trainingOptionList,
      }
      this.$emit('loading', true)
      this.uploadCount = 0
      this.$system.postJson(
        this.api + '/training/platform/addOrModifyTrainingTopic-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.$system.notify('成功', data.mess, 'success')
            this.$router.push({
              name: 'TopicManage',
              params: { fresh: true },
            })
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
            this.$emit('loading', false)
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
            this.$emit('loading', false)
          }
        },
        this.error
      )
    },
    clickError() { },
    /**
     * 文件预览
     */
    mouseenter(i, index) {
      this.$set(this.msForm.trainingOptionList[i].optionPicList[index], 'check', true)
    },
    mouseleave(i, index) {
      this.$set(this.msForm.trainingOptionList[i].optionPicList[index], 'check', false)
    },
    /**
     * 删除文件
     */
    deletionPositive(uuid, i, index) {
      let fileName = this.msForm.trainingOptionList[i].optionPicList[index].fileName
      this.msForm.trainingOptionList[i].optionPicList.splice(index, 1)
      this.$refs['msPositive' + uuid].deleteIndex(index)
      let fileList = this.$refs['msPositive' + uuid].showFileList || []
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs['msPositive' + uuid].deleteIndex(j)
            fileList = this.$refs['msPositive' + uuid].showFileList || []
            continue
          }
        }
      }
    },
    optionCodeValidate(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        var list = this.msForm.trainingOptionList
        var count = 0
        if (this.$zwxBase.verifyIsNotBlank(list)) {
          list.forEach(item => {
            if (value == item.optionCode) {
              count++
            }
          })
          if (count > 1) {
            return callback(new Error('选项编码不能重复'))
          }
        }
        return callback()
      } else {
        return callback(new Error('请输入选项编码'))
      }
    },
    /**
     * 单个文件预览
     */
    openFilePreview(fileName, filePath) {
      let previewFileList = []
      let item = {
        fileName: fileName,
        filePath: filePath,
      }
      previewFileList.push(item)
      this.openFilePreview1(previewFileList, 0)
    },
    /**
     * 多个文件预览
     */
    openFilePreview1(fileList, index) {
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: fileList,
            index: index,
          },
        },
        '*'
      )
    },
  },
}
</script>

<style lang="less" scoped>
.mstype {
  /deep/ .el-input__inner {
    width: 260px !important;
  }
  /deep/ .el-input {
    width: 260px !important;
  }
}
/deep/ .topic-select > .el-input.el-input--suffix > .el-input__inner {
  width: 260px !important;
}
/deep/ .if-answer {
  margin-right: 0px !important;
}
</style>
