import { ZwxCookie, ZwxSm } from 'vue-el-web'

/**
 * 表格数据中为空返回
 */
let formatNullDataByTable = (row, column, cellValue) => {
  if (window.vm.$zwxBase.verifyIsBlank(cellValue)) {
    return '--'
  } else {
    return cellValue
  }
}

/**
 * 非表格数据中为空返回
 */
let formatNullDataByNoTable = (cellValue, suffixName) => {
  if (window.vm.$zwxBase.verifyIsBlank(cellValue)) {
    return '--'
  } else {
    return cellValue + suffixName
  }
}

/**
 * 文件导出
 */
function exportExcel(url, data,cb) {
  const dateNow = Date.now() + Number(ZwxCookie.getCookieDefault('internetTimeDiff', 0))
  var xhr = new XMLHttpRequest()
  let url2 = url + '?ms=' + dateNow
  xhr.open('POST', url + '?ms=' + dateNow, true)
  //设置请求头参数
  xhr.setRequestHeader('Content-Type', 'application/json;charset=utf-8')
  xhr.setRequestHeader('tokenId', ZwxSm.sm2Encrypt123(ZwxSm.sm2Decrypt123(ZwxCookie.getCookie('msTokenId')) + ',' + dateNow))
  let headers = {}
  let filterData = window.vm.$system.createExportExcelFilterData(data)
  headers = window.vm.$system.createExportExcelTicket(url2, filterData,headers)
  xhr.setRequestHeader("tokenSign", headers.tokenSign)
  xhr.responseType = 'blob'
  xhr.onload = function(e) {
    if (this.status == 200) {
      let blob = this.response
      let a = document.createElement('a')
      let url = window.URL.createObjectURL(blob)
      a.href = url
      //获取后端文件名称
      let fileName = decodeURI(xhr.getResponseHeader('content-disposition'))
      if (fileName == 'null') {
        window.vm.$system.notify('提示', '暂无数据', 'info');
        return;
      }
      //截取=字符串后面的内容
      let str = fileName.match(/=(\S*)/)[1]
      a.download = str
      a.click()
      window.URL.revokeObjectURL(url)
      if(cb){
        cb()
      }
    }
  }
  //发送参数请求
  xhr.send(JSON.stringify(data))
}

export default { formatNullDataByTable, formatNullDataByNoTable, exportExcel }
