/**
 * test模块
 */
 import { PageLayout } from 'vue-el-web'
 // 一个菜单对应一个父级路由
 // 一个父级路由下面不允许存在多级children
 // noRequireAuth：表示不需要进行登录验证，也不需要进行菜单权限验证
 // menuRoute：表示需要进行登录验证，也需要进行菜单权限验证，但是路由切换不会再次校验登录信息
 // noMenuRoute：表示需要进行登录验证，但不需要进行菜单权限验证，路由切换会再次校验登录信息
 // 无：表示不需要进行登录验证，也不需要进行菜单权限验证，也表示该页面不能直接打开，需要通过路由跳转
 const viewsTest = [
   /**
    * 模块一
    */
   {
     path: '/',
     component: PageLayout,
     children: [
       
     ],
   },
   /**
    * 模块二
    */
   {
     path: '/',
     component: PageLayout,
     children: [
       
     ],
   },
 ]
 
 export default viewsTest
 