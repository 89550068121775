import ExamRecordDetail from '../components/business/exam-record-detail/index'
import SliderCheck from './layout/slider-check/slider-check.vue'
const components = [ExamRecordDetail, SliderCheck]

export default {
  install(Vue) {
    components.map(component => {
      Vue.component(component.name, component)
    })
  },
}
