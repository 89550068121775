/**
 * agency模块
 */
import { PageLayout } from 'vue-el-web'
// 一个菜单对应一个父级路由
// 一个父级路由下面不允许存在多级children
// noRequireAuth：表示不需要进行登录验证，也不需要进行菜单权限验证
// menuRoute：表示需要进行登录验证，也需要进行菜单权限验证，但是路由切换不会再次校验登录信息
// noMenuRoute：表示需要进行登录验证，但不需要进行菜单权限验证，路由切换会再次校验登录信息
// 无：表示不需要进行登录验证，也不需要进行菜单权限验证，也表示该页面不能直接打开，需要通过路由跳转
const viewsTest = [
  /**
   * 模板文件管理
   */
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/om/templateFileManagement',
        // path: '/repository/news/publishList',
        name: 'TemplateFileManagement',
        component: () => import('@/views/system/maintenance/TemplateFileManagement.vue'),
        meta: { menuRoute: true, title: '模板文件管理' },
      },
    ],
  },
  {
    path: '/accountAppeal',
    name: 'AccountAppeal',
    component: () => import('@/views/system/accountAppeal/Index.vue'),
    meta: { noRequireAuth: true },
  },
  {
    path: '/appealEdit',
    name: 'AppealEdit',
    component: () => import('@/views/system/accountAppeal/AppealEdit.vue'),
    meta: { noRequireAuth: true },
  },
  {
    path: '/appealProgress',
    name: 'AppealProgress',
    component: () => import('@/views/system/accountAppeal/AppealProgress.vue'),
    meta: { noRequireAuth: true },
  },
  {
    path: '/forgetPassword',
    name: 'ForgetPassword',
    component: () => import('@/views/system/forgetPassword/Index.vue'),
    meta: { noRequireAuth: true },
  },
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/om/hazardFactorStandardMax',
        name: 'HazardFactorStandardMax',
        component: () => import('@/views/system/maintenance/hazard_factor_standard/max.vue'),
        meta: { menuRoute: true, title: '危害因素接触限制维护' },
      },
    ],
  },
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/om/hazardFactorStandard',
        name: 'HazardFactorStandard',
        component: () => import('@/views/system/maintenance/hazard_factor_standard/MainPage.vue'),
        meta: { menuRoute: true, title: '危害因素管理' },
      },
    ],
  },
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/om/industryClassification',
        name: 'IndustryClassification',
        component: () => import('@/views/system/maintenance/industry_classification/MainPage.vue'),
        meta: { menuRoute: true, title: '行业分类目录' },
      },
      {
        path: '/om/industryClassification/edit',
        name: 'IndustryClassificationEdit',
        component: () => import('@/views/system/maintenance/industry_classification/EditPage.vue'),
        meta: { title: '编辑' },
      },
    ],
  },
  {
    path: '/',
    component: PageLayout,
    children: [
      {
        path: '/om/selfInspectionItem',
        name: 'SelfInspectionItem',
        component: () => import('@/views/system/maintenance/self_inspection_item/MainPage.vue'),
        meta: { menuRoute: true, title: '责任自查标准' },
      },
      {
        path: '/om/selfInspectionItem/edit',
        name: 'SelfInspectionItemEdit',
        component: () => import('@/views/system/maintenance/self_inspection_item/EditPage.vue'),
        meta: { title: '编辑' },
      },
    ],
  },
]

export default viewsTest
