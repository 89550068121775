import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    title: process.env.VUE_APP_TITLE,
    api: process.env.VUE_APP_API,
    file: process.env.VUE_APP_FILE,
    baseUrl: process.env.BASE_URL,
    unitName: process.env.VUE_APP_UNIT_NAME,
    domainNumber: process.env.VUE_APP_DOMAIN_NUMBER,
    psn: process.env.VUE_APP_PSN,
    psnNumber: process.env.VUE_APP_PSN_NUMBER,
    breadcrumbs: [],
    keepAlive: [],
    users: {},
    btns: [],
    ifBtns: false,
    customComponentName: 'CustomDropdown',
    roleExpList:window.localStorage.getItem('role')?JSON.parse(window.localStorage.getItem('role')):[]
  },
  mutations: {
    ADDROLELIST:(state,data)=>{
      state.roleExpList=data
    },
    BREADCRUMBS: (state, breadcrumb) => {
      const index = state.keepAlive.indexOf(breadcrumb.name)
      if (index === -1) {
        state.breadcrumbs.push({ title: breadcrumb.title, name: breadcrumb.name })
        state.keepAlive.push(breadcrumb.name)
      } else {
        state.breadcrumbs.splice(index + 1, state.breadcrumbs.length - index)
        state.keepAlive.splice(index + 1, state.keepAlive.length - index)
      }
    },
    INIT_USER: (state, users) => {
      state.users = users
    },
    INIT_BTNS: (state, btns) => {
      state.btns = btns
      state.ifBtns = true
    },
  },
  actions: {},
  modules: {},
})
