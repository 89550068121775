import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    title: process.env.VUE_APP_TITLE,
    version: process.env.VUE_APP_VERSION,
    api: process.env.VUE_APP_API.indexOf('http') == -1 ? window.location.protocol + '//' + window.location.host + process.env.VUE_APP_API : process.env.VUE_APP_API,
    file: process.env.VUE_APP_FILE.indexOf('http') == -1 ? window.location.protocol + '//' + window.location.host + process.env.VUE_APP_FILE : process.env.VUE_APP_FILE,
    baseUrl: process.env.BASE_URL,
    contactQQ: process.env.VUE_APP_CONTACT_QQ,
    contactPhone: process.env.VUE_APP_CONTACT_PHONE,
    unitName: process.env.VUE_APP_UNIT_NAME,
    domainNumber: process.env.VUE_APP_DOMAIN_NUMBER,
    psn: process.env.VUE_APP_PSN,
    psnNumber: process.env.VUE_APP_PSN_NUMBER,
    mailbox: process.env.VUE_APP_MAILBOX,
    breadcrumbs: [],
    keepAlive: [],
    config: {},
    users: {},
    btns: [],
    ifBtns: false,
    showWatermark: false,
    routerLoading: false,
  },
  mutations: {
    BREADCRUMBS: (state, breadcrumb) => {
      const index = state.keepAlive.indexOf(breadcrumb.name)
      if (index === -1) {
        state.breadcrumbs.push({ title: breadcrumb.title, name: breadcrumb.name })
        state.keepAlive.push(breadcrumb.name)
      } else {
        state.breadcrumbs.splice(index + 1, state.breadcrumbs.length - index)
        state.keepAlive.splice(index + 1, state.keepAlive.length - index)
      }
    },
    INIT_USER: (state, users) => {
      state.users = users
    },
    INIT_BTNS: (state, btns) => {
      state.btns = btns
      state.ifBtns = true
    },
    INIT_CONFIG: (state, config) => {
      state.config = config
    },
    ROUTER_LOADING: (state, routerLoading) => {
      state.routerLoading = routerLoading
    },
  },
  actions: {},
  modules: {},
})
