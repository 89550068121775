var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.roleExpList.length > 1
    ? _c(
        "el-dropdown-item",
        [
          _c(
            "el-popover",
            {
              attrs: {
                "popper-class": "ms-el-popper",
                placement: "left",
                trigger: "hover"
              }
            },
            [
              _c("span", { attrs: { slot: "reference" }, slot: "reference" }, [
                _vm._v("切换角色")
              ]),
              _c(
                "div",
                {
                  staticClass: "dropdown-list",
                  attrs: { slot: "default" },
                  slot: "default"
                },
                _vm._l(_vm.roleExpList, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class: [
                        "dropdown-list-item",
                        { selectItem: _vm.userRole == item.roleCode }
                      ],
                      on: {
                        click: function($event) {
                          return _vm.changeItem(item)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(item.roleName) + " ")]
                  )
                }),
                0
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }